import { useContext } from "react";
import { MendableSearchBar } from "@mendable/search";
import { REACT_APP_MENDABLE_API_KEY } from "../../config";
import { makeStyles } from "@mui/styles";
import AccountContext from "../../contexts/AccountContext";
import mendableChatIcon from "./../../assets/msg_bubble.png";
import mendableBotIcon from "./../../assets/RH_Chat_Avatar.png";
// import InputBase from "@mui/material/InputBase";
// import SearchIcon from "@mui/icons-material/Search";
// import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    alignItems: "center",
    fontSize: "14px",
    borderBottom: "1px solid rgba(255, 255, 255, 0.0)",
    marginRight: "20px",
    width: "300px",
    height: "35px",
    [theme.breakpoints.down("md")]: {
      width: "40px",
      borderBottom: "0px solid rgba(255, 255, 255, 0.0)",
      display: "none",
    },
  },
  searchIcon: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      color: "rgba(255, 255, 255, 0.5) !important",
    },
  },
  inputRoot: {
    paddingLeft: "40px", // width of the search icon
    height: "35px",
    width: "350px",
    [theme.breakpoints.down("lg")]: {
      width: "0px",
      paddingLeft: "0px",
    },
  },
}));

const RHMendableSearchBar = () => {
  const classes = useStyles();
  const { currentStateData } = useContext(AccountContext);
  const styles = {
    container: {
      display: "inline-block",
    },
    icon: {
      width: "36px",
      height: "36px",
    },
  };
  const handleSearch = (event) => {
    if (event.key === "Enter" || event.type === "click") {
      // Perform your search action here
      console.log("Search initiated");
    }
  };

  return (
    <div className={classes.search}>
      <MendableSearchBar
        anon_key={REACT_APP_MENDABLE_API_KEY}
        cmdShortcutKey="k"
        placeholder="Search..."
        welcomeMessage="Hello, ask any question!"
        userIcon={<img src={mendableChatIcon} alt="Chat Icon" style={styles.icon} />}
        botIcon={<img src={mendableBotIcon} alt="Bot Icon" />}
        context={
          "Here are user details to help you answer user's specific questions: " +
          "org id: " +
          currentStateData?.org?.orgId +
          "\n" +
          "product id: " +
          currentStateData?.product?.productId +
          "\n" +
          "sandbox id: " +
          currentStateData?.sandbox?.sandboxId +
          "\n"
        }
        searchBarStyle={{
          backgroundColor: "#1F2937",
          borderColor: "#6B728033",
          color: "#E1E3E7",
          shadow: false,
        }}
      >
        <img src={mendableChatIcon} alt="Chat Icon" style={styles.icon} />
        <img src={mendableBotIcon} alt="Bot Icon" />
      </MendableSearchBar>
    </div>
  );
};
export default RHMendableSearchBar;
