import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { EsportsIcon } from "../../assets/svgs";
import AccountContext from "../../contexts/AccountContext";
import { generateColor } from "../../common/colorUtils";
import { Link, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  return {
    rootOrgPage: {
      display: "flex",
      flexWrap: "wrap",
      gap: "20px",
    },
    itemBox: {
      display: "flex",
      flexDirection: "column",
      height: "188px",
      justifyContent: "center",
      width: "352px",
      backgroundColor: "red",
      cursor: "pointer",
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    iconColor: {
      color: "rgba(255, 255, 255, 0.7)",
    },
  };
});

export default function RHOrgPage() {
  const classes = useStyles();
  const location = useLocation();

  const { currentStateData } = useContext(AccountContext);

  return !currentStateData?.org?.products ? null : (
    <Box className={classes.rootOrgPage}>
      {currentStateData.org?.products
        .filter((x) => !x?.archive)
        .map((product) => (
          <Link to={`${location.pathname}/product/${product.shortName}`} key={product.productId}>
            <Box
              className={classes.itemBox}
              sx={{
                background: generateColor(product.productId),
                alignItems: "center",
                border: 1,
                borderColor: "text.secondary",
                borderRadius: "4px",
              }}
            >
              <EsportsIcon width="64" />
              <Typography key={product.productName} color="textPrimary" variant="h5">
                {product.productName}
              </Typography>
            </Box>
          </Link>
        ))}
    </Box>
  );
}
