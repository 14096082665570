const routeNames = {
  items: "Item",
  loot: "Loot",
  vendors: "Vendors",
  xp: "XP",
  "rule-sets": "Rule Set",
  "pricing-config": "Price Point",
  queues: "Queues",
  "session-templates": "Session Templates",
  maps: "Maps",
  "instance-request-templates": "Instance Request Templates",
  ranks: "Ranks",
  "map-selection-list": "Map Selection List",
  "matchmaking-profile": "Matchmaking Profile",
  "matchmaking-profile-list": "Matchmaking Profile List",
  "matchmaking-rule": "Matchmaking Rule",
  "matchmaking-stride": "Matchmaking Stride",
  "matchmaking-template": "Matchmaking Template",
  "matchmaking-template-group": "Matchmaking Template Group",
  "crossplay-profile": "Crossplay Profile",
  "crossplay-partition": "Crossplay Partition",
  "crossplay-pool": "Crossplay Pool",
  "crossplay-pool-tag": "Crossplay Pool Tag",
  "game-config": "Game Configuration",
  "kv-editor": "KV",
  orgstructure: "Org Structure",
  permissions: "Permissions",
  "setting-types": "Setting Types",
  roles: "Roles",
  "entitlement-sku": "Entitlement SKUs",
  customEndpoints: "Custom Endpoints",
  "audit-logs": "Audit Logs",
  portaladmin: "Portal Admin",
  userManagement: "User Management",
  armada: "Armada",
  "geo-location": "Geographic Locations",
  "dc-provider": "Datacenter Providers",
  "dc-location": "Datacenter Locations",
  "machine-image": "Machine Images",
  "dc-contract": "Datacenter Contracts",
  "machine-class": "Machine Classes",
  "product-assignment": "Simple Product Assignments",
  host: "Hosts",
  "account-management": "Account Management",
  policies: "Policy",
  clients: "Client",
  tools: "Tools",
  options: "Options",
  "game-events": "Game Events",
  general: "Game Configuration",
  anon: "Game Configuration",
  basic: "Game Configuration",
  social: "Game Configuration",
  playstation: "Game Configuration",
  epic: "Game Configuration",
  steam: "Game Configuration",
  nintendo: "Game Configuration",
  twitch: "Game Configuration",
  amazon: "Game Configuration",
  google: "Game Configuration",
  apple: "Game Configuration",
  xbox: "Game Configuration",
  vivox: "Game Configuration",
  legacy: "Game Configuration",
};

export default routeNames;
