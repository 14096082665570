import { useState } from "react";
import { Controlled as CodeMirror } from "react-codemirror2";

import { makeStyles } from "@mui/styles";
import InputAdornment from "@mui/material/InputAdornment";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Switch from "@mui/material/Switch";
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import { FormGroup, FormControlLabel } from "@mui/material";

import { TabPanel, TabList, TabContext } from "@mui/lab";
import { useApiToken, API_BASE } from "common/apiUtils";

const useStyles = makeStyles((theme) => {
  return {
    rootWrapUnwrapBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "Left",
      width: "100%",
      padding: "30px",
      backgroundColor: theme.palette.background.paper,
      flex: 1,
    },
    CodeMirror: {
      margin: "10px",
    },
    switchLabel: {
      color: "white",
    },
  };
});

export default function RHSecretWrapUnWrap() {
  const classes = useStyles();
  const token = useApiToken();

  const [selectedTab, setSelectedTab] = useState("1");
  const [showJSONAlert, setShowJSONAlert] = useState(false);
  const [unwrappedTokenValue, setUnwrappedTokenValue] = useState("");
  const [unwrappedDataValue, setUnwrappedDataValue] = useState("");
  const [dataToWrap, setDataToWrap] = useState("secret_goes_here");
  const [wrappedTokenValue, setWrappedTokenValue] = useState("");
  const [jsonSwitch, setJsonSwitch] = useState(false);
  const [wrapLoading, setWrapLoading] = useState(false);
  const [unwrapLoading, setUnwrapLoading] = useState(false);
  const [unwrapDisabled, setUnwrapDisabled] = useState(true);
  const [wrapDisabled, setWrapDisabled] = useState(false);
  const [wrappedError, setWrappedError] = useState(false);
  const [unwrappedError, setUnwrappedError] = useState(false);
  const [unwrappingErrorText, setUnwrappingErrorText] = useState("");
  const [beginWrap, setBeginWrap] = useState(false);
  const [dataToWrapNow, setDataToWrapNow] = useState("");

  async function unwrap() {
    // Because it returns a single string without quotes, this couldn't use the apiRequest function
    setUnwrappedError(false);
    setUnwrapLoading(true);
    try {
      const response = await fetch(`${API_BASE}/v1/secret/unwrap/${unwrappedTokenValue}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.text();
      setUnwrappedDataValue(data);
    } catch (e) {
      setUnwrappedError(true);
      setUnwrappingErrorText(e.message);
      return;
    }
    setUnwrapLoading(false);
  }

  async function wrap() {
    setWrappedError(false);
    setWrapLoading(true);

    try {
      let wrappedDataToSend;
      if (jsonSwitch === false) wrappedDataToSend = dataToWrap.replace(/"/g, '\\"');
      else wrappedDataToSend = JSON.parse(dataToWrap);

      // Why did I have to specifcy the Content-Type header here, but not in the unwrap function?
      const response = await fetch(`${API_BASE}/v1/secret/wrap`, {
        method: "POST",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        body: JSON.stringify(wrappedDataToSend),
      });
      const data = await response.json();

      setWrappedTokenValue(data);
      setWrapLoading(false);
    } catch (e) {
      setWrappedError(true);
      setWrapLoading(false);
    }
  }

  const handleJsonSwitchChange = (event) => {
    if (event.target.checked) {
      setDataToWrap(JSON.stringify({ secret: "goes_here" }, null, 2));
      setJsonSwitch(true);
    } else {
      setDataToWrap("secret goes here");
      setJsonSwitch(false);
    }
  };

  const validateWrapData = (editor, data, value) => {
    setWrappedTokenValue("");

    if (jsonSwitch) {
      try {
        JSON.parse(dataToWrap);
        setShowJSONAlert(false);
        setWrapDisabled(false);
      } catch (e) {
        setWrapDisabled(true);
        setShowJSONAlert(true);
        return;
      }
    }

    if (value.length > 0) {
      setWrapDisabled(false);
    } else {
      setWrapDisabled(true);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(wrappedTokenValue);
  };

  const handleTabChange = (event, tabIndex) => {
    setSelectedTab(tabIndex);
  };

  return (
    <Box className={classes.rootWrapUnwrapBox}>
      <TabContext value={selectedTab}>
        <TabList onChange={handleTabChange}>
          <Tab label="Secret Wrap" value="1" />
          <Tab label="Secret Unwrap" value="2" />
        </TabList>
        <TabPanel value="1">
          <FormGroup>
            <FormControlLabel
              control={<Switch onChange={handleJsonSwitchChange} checked={jsonSwitch} />}
              label="JSON"
              classes={{ label: classes.switchLabel }}
            />
          </FormGroup>
          <CodeMirror
            value={dataToWrap}
            options={{
              mode: "javascript",
              theme: "pastel-on-dark",
              json: true,
              lineNumbers: true,
            }}
            onBeforeChange={(editor, data, value) => {
              setDataToWrap(value);
            }}
            onChange={validateWrapData}
          />
          <Collapse in={showJSONAlert}>
            <Alert severity="warning" hidden>
              Payload is not valid JSON
            </Alert>
          </Collapse>
          <br />
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item>
              <LoadingButton
                variant="contained"
                size="large"
                loading={wrapLoading}
                disabled={wrapDisabled}
                onClick={wrap}
              >
                Wrap Secret
              </LoadingButton>
            </Grid>
            <Grid item xs>
              <TextField
                value={wrappedTokenValue}
                label="Token"
                disabled
                size="small"
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Copy Token">
                        <Button size="small" onClick={handleCopy}>
                          <FileCopyIcon style={{ color: "grey", minWidth: "0px" }} fontSize="small" />
                        </Button>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <br />
          <Collapse in={wrappedError}>
            <Alert severity="warning" hidden>
              Error wrapping the secret.
            </Alert>
          </Collapse>
        </TabPanel>
        <TabPanel value="2">
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs>
              <TextField
                label="Token"
                size="small"
                variant="outlined"
                fullWidth
                onChange={(event) => {
                  setUnwrappedTokenValue(event.target.value);
                  setUnwrapDisabled(event.target.value < 1);
                }}
              />
            </Grid>

            <Grid item>
              <LoadingButton
                variant="contained"
                size="large"
                color="primary"
                loading={unwrapLoading}
                disabled={unwrapDisabled}
                onClick={unwrap}
              >
                Unwrap Secret
              </LoadingButton>
            </Grid>
          </Grid>
          <br />
          <Collapse in={unwrappedError}>
            <Alert severity="warning" hidden>
              {unwrappingErrorText}
            </Alert>
          </Collapse>
          <hr />
          <CodeMirror
            value={unwrappedDataValue}
            options={{
              mode: "javascript",
              theme: "pastel-on-dark",
              json: true,
              lineNumbers: true,
              readOnly: true,
            }}
          />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
