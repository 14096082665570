import {
  TableChartIcon,
  ManageHistoryIcon,
  QueuePlayNextIcon,
  PermDataSettingIcon,
  AdminPanelSettingsIcon,
  RecentActorsIcon,
  LinkIcon,
  ManageAccountsIcon,
  HandShakeIcon,
  ConnectingAirportsIcon,
  KeyIcon,
  EventAvailableIcon,
  StorageIcon,
} from "../../assets/svgs";
import PsychologyIcon from "@mui/icons-material/Psychology";

const sandboxConfigElements = [
  {
    name: "Catalog",
    icon: <TableChartIcon classes={`inline-block mr-2`} />,
    nestedItems: [
      {
        name: "Items",
        route: "items",
      },
      {
        name: "Loot",
        route: "loot",
      },
      {
        name: "Vendors",
        route: "vendors",
      },
      {
        name: "XP Tables Config",
        route: "xp",
      },
      {
        name: "Rule Sets",
        route: "rule-sets",
      },
      {
        name: "Pricing Config",
        route: "pricing-config",
      },
      {
        name: "Entitlement SKUs",
        route: "entitlement-sku",
      },
      {
        name: "External Key Campaigns",
        route: "external-key-campaign",
      },
    ],
  },
  {
    name: "Game Flow",
    icon: <QueuePlayNextIcon classes={`inline-block mr-2`} />,
    nestedItems: [
      {
        name: "Session Templates",
        route: "session-templates",
      },
      {
        name: "Maps",
        route: "maps",
      },
      {
        name: "Map Selection List",
        route: "map-selection-list",
      },

      {
        name: "Instance Request Templates",
        route: "instance-request-templates",
      },
    ],
  },
  {
    name: "Matchmaking",
    icon: <HandShakeIcon classes={`inline-block mr-2`} />,
    nestedItems: [
      {
        name: "Ranks",
        route: "ranks",
      },
      {
        name: "Stride",
        route: "matchmaking-stride",
      },

      {
        name: "Profile",
        route: "matchmaking-profile-list",
      },
      {
        name: "Queues",
        route: "queues",
      },
      {
        name: "Deserter",
        route: "deserterConfig",
      },
    ],
  },
  {
    name: "Advanced Matchmaking",
    icon: <PsychologyIcon style={{ marginRight: "7px" }} />,
    nestedItems: [
      {
        name: "Template Group",
        route: "matchmaking-template-group",
      },
      {
        name: " Rule Set",
        route: "matchmaking-ruleset",
      },
      {
        name: "Profile",
        route: "matchmaking-profile",
      },
      {
        name: "Profile List",
        route: "matchmaking-profile-list-advanced",
      },
    ],
  },
  {
    name: "Crossplay",
    icon: <ConnectingAirportsIcon classes={`inline-block mr-2`} />,
    nestedItems: [
      {
        name: "Crossplay Partition",
        route: "crossplay-partition",
      },
      {
        name: "Crossplay Profile",
        route: "crossplay-profile",
      },
      {
        name: "Crossplay Pool",
        route: "crossplay-pool",
      },
      {
        name: "Crossplay Pool Tag",
        route: "crossplay-pool-tag",
      },
    ],
  },
  {
    name: "Game Config",
    icon: <PermDataSettingIcon classes={`inline-block mr-2`} />,
    nestedItems: [
      {
        name: "General",
        route: "game-config/general",
      },
      {
        name: "Anon",
        route: "game-config/anon",
      },
      {
        name: "Social",
        route: "game-config/social",
      },
      {
        name: "Playstation",
        route: "game-config/playstation",
      },
      {
        name: "Epic",
        route: "game-config/epic",
      },
      {
        name: "Steam",
        route: "game-config/steam",
      },
      {
        name: "Nintendo",
        route: "game-config/nintendo",
      },
      {
        name: "Twitch",
        route: "game-config/twitch",
      },
      {
        name: "Amazon",
        route: "game-config/amazon",
      },
      {
        name: "Google",
        route: "game-config/google",
      },
      {
        name: "Apple",
        route: "game-config/apple",
      },
      {
        name: "Xbox",
        route: "game-config/xbox",
      },
      {
        name: "Vivox",
        route: "game-config/vivox",
      },
      {
        name: "Legacy",
        route: "game-config/legacy",
      },
    ],
  },
  {
    name: "KV Editor",
    route: "kv-editor",
    icon: <KeyIcon classes={`inline-block mr-2`} />,
  },
  {
    name: "Game Events",
    route: "game-events",
    icon: <EventAvailableIcon classes={`inline-block mr-2`} />,
  },
  {
    name: "Setting Types",
    route: "setting-types",
    icon: <ManageAccountsIcon classes={`inline-block mr-2`} />,
  },
  {
    name: "Custom Endpoints",
    route: "custom-endpoints",
    icon: <LinkIcon classes={`inline-block mr-2`} />,
  },
  {
    name: "Roles",
    route: "roles",
    icon: <AdminPanelSettingsIcon classes={`inline-block mr-2`} />,
  },
];

export default sandboxConfigElements;
