import { createContext, useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
const API_BASE =
  process.env.NODE_ENV === "production"
    ? `${window.env.REACT_APP_API_BASE_PATH}/v1`
    : `${process.env.REACT_APP_API_BASE_PATH}/v1`;

const AccountContext = createContext();

function AccountContextProvider({ children, props }) {
  const location = useLocation();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth();

  const [permissionData, setPermissionData] = useState();
  const [orgsData, setOrgsData] = useState();
  const [currentStateData, setCurrentStateData] = useState();

  let sandboxCopyEnabled = useRef();

  function sandboxCopyIsEnabled() {
    if (sandboxCopyEnabled.current) {
      return sandboxCopyEnabled.current;
    }
    const isDev = document?.location?.host != "developer.platform-dev.rally-here.io";
    const isLocal = process.env.NODE_ENV != "production";
    const localAlwaysOn = true;
    if (localAlwaysOn) {
      sandboxCopyEnabled.current = true;
      return sandboxCopyEnabled.current;
    }
    if (isLocal || isDev) {
      let params = new URL(document.location).searchParams;
      sandboxCopyEnabled.current = params.has("enable_sandbox_copy");
    } else {
      sandboxCopyEnabled.current = false;
    }
    return sandboxCopyEnabled.current;
  }

  async function getPermissionData() {
    return (
      await fetch(`${API_BASE}/account-permissions-auth0`, {
        headers: new Headers({
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          "Content-Type": "application/json",
        }),
      })
    ).json();
  }

  async function getMenuData(accountId) {
    return (
      await fetch(`${API_BASE}/menu-data/${accountId}`, {
        headers: new Headers({
          Authorization: `Bearer ${await getAccessTokenSilently()}`,
          "Content-Type": "application/json",
        }),
      })
    ).json();
  }

  function addPermsToMenu(menu, flatPerms, isGlobalAdmin) {
    const GLOBAL_ADMIN_PERM = "globalAdmin:*:*";

    return menu.reduce((acc, org) => {
      acc[org.shortName] = org;
      if (isGlobalAdmin) acc[org.shortName].permissions = [GLOBAL_ADMIN_PERM];
      else if (flatPerms[org.orgId]) acc[org.shortName].permissions = flatPerms[org.orgId];
      org?.products.forEach((product) => {
        if (!acc[org.shortName].keyedProducts) acc[org.shortName].keyedProducts = {};
        acc[org.shortName].keyedProducts[product.shortName] = product;
        if (isGlobalAdmin) acc[org.shortName].keyedProducts[product.shortName].permissions = [GLOBAL_ADMIN_PERM];
        else if (flatPerms[product.productId]) {
          acc[org.shortName].keyedProducts[product.shortName].permissions = flatPerms[product.productId];
        }
        product?.sandboxes.forEach((sandbox) => {
          if (!acc[org.shortName].keyedProducts?.[product.shortName]?.keyedSandboxes)
            acc[org.shortName].keyedProducts[product.shortName].keyedSandboxes = {};
          acc[org.shortName].keyedProducts[product.shortName].keyedSandboxes[sandbox.shortName] = sandbox;
          if (isGlobalAdmin)
            acc[org.shortName].keyedProducts[product.shortName].keyedSandboxes[sandbox.shortName].permissions = [
              GLOBAL_ADMIN_PERM,
            ];
          else if (flatPerms[sandbox.sandboxId])
            acc[org.shortName].keyedProducts[product.shortName].keyedSandboxes[sandbox.shortName].permissions =
              flatPerms[sandbox.sandboxId];
        });
        product?.environments.forEach((environment) => {
          if (!acc[org.shortName].keyedProducts?.[product.shortName]?.keyedEnvironments)
            acc[org.shortName].keyedProducts[product.shortName].keyedEnvironments = {};
          acc[org.shortName].keyedProducts[product.shortName].keyedEnvironments[environment.shortName] = environment;
          if (isGlobalAdmin)
            acc[org.shortName].keyedProducts[product.shortName].keyedEnvironments[environment.shortName].permissions = [
              GLOBAL_ADMIN_PERM,
            ];
          else if (flatPerms[environment.environmentId])
            acc[org.shortName].keyedProducts[product.shortName].keyedEnvironments[environment.shortName].permissions =
              flatPerms[environment.environmentId];
        });
      });

      return acc;
    }, {});
  }

  function flattenPermissions(menu, permissions) {
    return permissions.reduce((acc, curr) => {
      if (!curr.permissions) {
        if (curr.org_id) {
          if (!acc?.[curr.org_id]) acc[curr.org_id] = [];
          acc[curr.org_id].push(curr.permission_id);
          menu?.assignedEntities.orgs
            .find((org) => org.orgId === curr.org_id)
            .products.forEach((product) => {
              if (!acc[product.productId]) acc[product.productId] = [];
              acc[product.productId].push(curr.permission_id);
              product?.sandboxes.forEach((sandbox) => {
                if (!acc[sandbox.sandboxId]) acc[sandbox.sandboxId] = [];
                acc[sandbox.sandboxId].push(curr.permission_id);
              });
            });
        }

        if (curr.product_id) {
          if (!acc[curr.product_id]) acc[curr.product_id] = [];

          acc[curr.product_id].push(curr.permission_id);
          menu?.assignedEntities.orgs.forEach((org) => {
            const product = org?.products.find((product) => product.productId === curr.product_id);
            product?.sandboxes.forEach((sandbox) => {
              if (!acc[sandbox.sandboxId]) acc[sandbox.sandboxId] = [];
              acc[sandbox.sandboxId].push(curr.permission_id);
            });
            product?.environments.forEach((environment) => {
              if (!acc[environment.environmentId]) acc[environment.environmentId] = [];
              acc[environment.environmentId].push(curr.permission_id);
            });
          });
        }

        if (curr.environment_id) {
          menu?.assignedEntities.orgs.forEach((org) => {
            org?.products.forEach((product) => {
              product?.environments.forEach((environment) => {
                if (environment.environmentId === curr.environment_id) {
                  if (!acc[curr.environment_id]) acc[curr.environment_id] = [];
                  acc[curr.environment_id].push(curr.permission_id);
                }
              });
            });
          });
        }

        if (curr.sandbox_id) {
          menu?.assignedEntities.orgs.forEach((org) => {
            org?.products.forEach((product) => {
              product?.sandboxes.forEach((sandbox) => {
                if (sandbox.sandboxId === curr.sandbox_id) {
                  if (!acc[curr.sandbox_id]) acc[curr.sandbox_id] = [];
                  acc[curr.sandbox_id].push(curr.permission_id);
                }
              });
            });
          });
        }
      }
      return acc;
    }, {});
    // return acc;
  }

  function getEntityNamesFromUrl() {
    const currentLocation = location.pathname.split("/");
    currentLocation.shift(); // Remove the first empty string element

    const ORG = "org";
    const PRODUCT = "product";
    const SANDBOX = "sandbox";
    const ENVIRONMENT = "environment";

    // Extract the segment following the sandbox
    const sandboxIndex = currentLocation.indexOf(SANDBOX);
    const apiIdIndex =
      sandboxIndex !== -1 && currentLocation.length > sandboxIndex + 2 ? currentLocation[sandboxIndex + 2] : undefined;

    return {
      org: currentLocation.includes(ORG) ? currentLocation[currentLocation.indexOf(ORG) + 1] : undefined,
      product: currentLocation.includes(PRODUCT) ? currentLocation[currentLocation.indexOf(PRODUCT) + 1] : undefined,
      sandbox: currentLocation.includes(SANDBOX) ? currentLocation[currentLocation.indexOf(SANDBOX) + 1] : undefined,
      environment: currentLocation.includes(ENVIRONMENT)
        ? currentLocation[currentLocation.indexOf(ENVIRONMENT) + 1]
        : undefined,
      apiId: apiIdIndex, // This will be the segment following the sandbox
    };
  }

  async function getCurrentStateData() {
    const entityNames = getEntityNamesFromUrl();

    setCurrentStateData({
      org: orgsData?.[entityNames.org],
      product: orgsData?.[entityNames.org]?.keyedProducts?.[entityNames.product],
      sandbox: orgsData?.[entityNames.org]?.keyedProducts?.[entityNames.product]?.keyedSandboxes?.[entityNames.sandbox],
      environment:
        orgsData?.[entityNames.org]?.keyedProducts?.[entityNames.product]?.keyedEnvironments?.[entityNames.environment],
      apiId: entityNames.apiId, // Include the next segment after sandbox in your state
    });
  }

  async function initAppData() {
    const permissionDataRes = await getPermissionData();
    const menuDataRes = await getMenuData(permissionDataRes?.account_id);

    const groupPerms = (permissionDataRes?.groupPermissions || []).reduce((perms, group) => {
      perms = perms.concat(group.permissions);
      return perms;
    }, []);

    const allPerms = permissionDataRes ? (permissionDataRes.accountPermissions || []).concat(groupPerms || []) : [];

    const flatPerms = flattenPermissions(menuDataRes, allPerms);

    const keyedMenu = addPermsToMenu(menuDataRes?.assignedEntities?.orgs, flatPerms, permissionDataRes.is_global_admin);

    setPermissionData({
      permissions: allPerms,
      isGlobalAdmin: permissionDataRes.is_global_admin,
      account_id: permissionDataRes?.account_id,
    });
    setOrgsData(keyedMenu);

    return keyedMenu;
  }

  function refreshAccount() {
    initAppData();
  }

  function hasPermission(permission_to_check, level, level_id, currentStateData, permissionData) {
    if (
      permissionData?.isGlobalAdmin ||
      currentStateData?.[level]?.permissions?.includes(permission_to_check) ||
      permissionData?.permissions.find(
        (x) =>
          (x[`${level}_id`] === level_id && x.permission_id === permission_to_check) ||
          (x.product_id === currentStateData.product.productId && x.permission_id === permission_to_check) ||
          (x.org_id === currentStateData.org.orgId && x.permission_id === permission_to_check)
      )
    ) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (orgsData) {
      const lastVisitedProduct = localStorage.getItem("lastVisitedProduct");
      const lastVisitSplit = lastVisitedProduct?.split("/");
      lastVisitSplit?.shift();

      const lastOrg = lastVisitSplit && lastVisitSplit[lastVisitSplit.indexOf("org") + 1];
      const lastProduct = lastVisitSplit && lastVisitSplit[lastVisitSplit.indexOf("product") + 1];

      if (location.pathname === "/") {
        if (lastVisitedProduct && orgsData[lastOrg] && orgsData[lastOrg].keyedProducts[lastProduct]) {
          history.push(lastVisitedProduct);
        } else {
          localStorage.removeItem("lastVisitedProduct");
          history.push("/org/" + Object.values(orgsData).filter((value) => !value.archive)[0].shortName);
        }
      }

      const pathSplit = location.pathname.split("/");
      pathSplit.shift();
      const org = pathSplit[pathSplit.indexOf("org") + 1];
      const product = pathSplit[pathSplit.indexOf("product") + 1];

      if (orgsData?.[org] && orgsData?.[org]?.keyedProducts?.[product]) {
        localStorage.setItem("lastVisitedProduct", location.pathname.split("/sandbox")[0]);
      }
    }

    //Remove trailing slash, as long as it's not the root
    if (location.pathname.length > 1 && location.pathname.endsWith("/")) {
      history.push(location.pathname.slice(0, -1));
    }

    getCurrentStateData();
  }, [orgsData, location.pathname]);

  useEffect(() => {
    initAppData();
  }, []);

  return (
    <AccountContext.Provider
      value={{
        orgsData,
        permissionData,
        currentStateData,
        refreshAccount,
        hasPermission,
        sandboxCopyIsEnabled,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export { AccountContext as default, AccountContextProvider };
