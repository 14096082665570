import { useContext, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import { useSandboxStyles } from "../sandbox/styles";
import AccountContext from "contexts/AccountContext";
import RHLoadingMask from "components/common/RHLoadingMask";
import RHEditableTable from "components/common/RHEditableTable";
import { fetchDirectly } from "common/apiUtils";
import { useAuth } from "contexts/AuthContext";
import * as helpers from "common/helpers";
import {
  orgTableColumns,
  orgTableRowKey,
  orgTableEntry,
  orgApi,
  productTableColumns,
  productTableRowKey,
  productTableEntry,
  productApi,
  sandboxTableColumns,
  sandboxTableRowKey,
  sandboxTableEntry,
  sandboxApi,
} from "./definitions";

export default function RHOrgStructure() {
  const classes = useSandboxStyles();
  const { permissionData } = useContext(AccountContext);
  const { getAccessTokenSilently } = useAuth();
  const [orgData, setOrgData] = useState();
  const [productData, setProductData] = useState();
  const [sandboxData, setSandboxData] = useState();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedSandbox, setSelectedSandbox] = useState();
  const [selectedTableRow, setSelectedTableRow] = useState();
  const [selectedChildTableRow, setSelectedChildTableRow] = useState();
  const [hasEditPermission, setHasEditPermission] = useState(false);
  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });

  const tableApi = orgApi;
  const tableRowKey = orgTableRowKey;
  const tableColumns = orgTableColumns;
  const tableVisibleColumns = ["org_id", "name", "short_name", "primary_contact_email", "archive"];
  const orderedColumns = helpers.getOrderedColumns(tableVisibleColumns, tableColumns);
  const tableInitialState = helpers.getInitialTableState(tableVisibleColumns, tableColumns);
  const tableFriendlyName = "Orgs";
  const tableEntry = orgTableEntry;

  const childTableApi = productApi;
  const childTableRowKey = productTableRowKey;
  const childTableColumns = productTableColumns;
  const childTableVisibleColumns = ["product_id", "name", "short_name", "primary_contact_email", "archive"];
  const childOrderedColumns = helpers.getOrderedColumns(childTableVisibleColumns, childTableColumns);
  const childTableInitialState = helpers.getInitialTableState(childTableVisibleColumns, childTableColumns);
  const childTableFriendlyName = "Products";
  const childTableEntry = productTableEntry;

  const grandChildTableApi = sandboxApi;
  const grandChildTableColumns = sandboxTableColumns;
  const grandChildTableVisibleColumns = [
    "sandbox_id",
    "name",
    "short_name",
    "primary_contact_email",
    "classic_support_tool_url",
    "archive",
  ];
  const grandChildOrderedColumns = helpers.getOrderedColumns(grandChildTableVisibleColumns, grandChildTableColumns);
  const grandChildTableInitialState = helpers.getInitialTableState(
    grandChildTableVisibleColumns,
    grandChildTableColumns
  );

  const grandChildTableFriendlyName = "Sandboxes";
  const grandChildTableEntry = sandboxTableEntry;

  async function fetchTableData() {
    try {
      let data = await fetchDirectly({ token: await getAccessTokenSilently(), fullUrl: `v1/org` });
      setOrgData(data);
      setHasEditPermission(permissionData?.isGlobalAdmin);
    } catch (error) {
      console.error("Error message:", error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function updateOrgHandler(apiName, onDataRefresh, key, sandboxId, editedItem, customArgs) {
    try {
      let data = await fetchDirectly({
        token: await getAccessTokenSilently(),
        fullUrl: `v1/org/${key}`,
        method: "put",
        body: editedItem,
      });
      fetchTableData();
      setSelectedTableRow(data);
      setSnackBarState(true);
      setSnackBar({ message: "Org updated successfully", severity: "success" });
      return data;
    } catch (error) {
      console.error("Error message:", error.message);
      setSnackBarState(true);
      setSnackBar({ message: "Error updating org", severity: "error" });
    }
  }

  async function updateProductHandler(apiName, onDataRefresh, key, sandboxId, editedItem, customArgs) {
    try {
      let data = await fetchDirectly({
        token: await getAccessTokenSilently(),
        fullUrl: `v1/org/${selectedTableRow?.[tableRowKey]}/product/${key}`,
        method: "put",
        body: editedItem,
      });
      fetchChildTableData();
      setSelectedChildTableRow(data);
      setSnackBarState(true);
      setSnackBar({ message: "Product updated successfully", severity: "success" });
      return data;
    } catch (error) {
      console.error("Error message:", error.message);
      setSnackBarState(true);
      setSnackBar({ message: "Error updating product", severity: "error" });
    }
  }

  async function updateSandboxHandler(apiName, onDataRefresh, key, sandboxId, editedItem, customArgs) {
    try {
      let data = await fetchDirectly({
        token: await getAccessTokenSilently(),
        fullUrl: `v1/org/${selectedTableRow?.[tableRowKey]}/product/${selectedChildTableRow?.[childTableRowKey]}/sandbox/${key}`,
        method: "put",
        body: editedItem,
      });
      fetchGrandChildTableData();
      setSnackBarState(true);
      setSnackBar({ message: "Sandbox updated successfully", severity: "success" });
      return data;
    } catch (error) {
      console.error("Error message:", error.message);
      setSnackBarState(true);
      setSnackBar({ message: "Error updating sandbox", severity: "error" });
    }
  }

  async function createOrgHandler(apiName, onDataRefresh, sandboxId, editedItem, customArgs) {
    try {
      let data = await fetchDirectly({
        token: await getAccessTokenSilently(),
        fullUrl: `v1/org`,
        method: "post",
        body: editedItem,
      });
      fetchTableData();
      setSnackBarState(true);
      setSnackBar({ message: "Org created successfully", severity: "success" });
      return data;
    } catch (error) {
      console.error("Error message:", error.message);
      setSnackBarState(true);
      setSnackBar({ message: "Error updating org", severity: "error" });
    }
  }

  async function createProductHandler(apiName, onDataRefresh, sandboxId, editedItem, customArgs) {
    try {
      let data = await fetchDirectly({
        token: await getAccessTokenSilently(),
        fullUrl: `v1/org/${selectedTableRow?.[tableRowKey]}/product`,
        method: "post",
        body: editedItem,
      });
      fetchChildTableData();
      setSelectedChildTableRow(data);
      setSnackBarState(true);
      setSnackBar({ message: "Product created successfully", severity: "success" });
      return data;
    } catch (error) {
      console.error("Error message:", error.message);
      setSnackBarState(true);
      setSnackBar({ message: "Error updating product", severity: "error" });
    }
  }

  async function createSandboxHandler(apiName, onDataRefresh, sandboxId, editedItem, customArgs) {
    try {
      let data = await fetchDirectly({
        token: await getAccessTokenSilently(),
        fullUrl: `v1/org/${selectedTableRow?.[tableRowKey]}/product/${selectedChildTableRow?.[childTableRowKey]}/sandbox`,
        method: "post",
        body: editedItem,
      });
      fetchGrandChildTableData();
      setSnackBarState(true);
      setSnackBar({ message: "Sandbox created successfully", severity: "success" });
      return data;
    } catch (error) {
      console.error("Error message:", error.message);
      setSnackBarState(true);
      setSnackBar({ message: "Error updating product", severity: "error" });
    }
  }

  async function deleteOrgHandler(apiName, onDataRefresh, rowKey, sandboxId, selectedItem, customArgs) {
    try {
      let data = await fetchDirectly({
        token: await getAccessTokenSilently(),
        fullUrl: `v1/org/${rowKey}`,
        method: "delete",
      });
      fetchTableData();
      setSnackBarState(true);
      setSnackBar({ message: "Org deleted successfully", severity: "success" });
      return data;
    } catch (error) {
      console.error("Error message:", error.message);
      setSnackBarState(true);
      setSnackBar({ message: "Error deleting org", severity: "error" });
    }
  }

  async function deleteProductHandler(apiName, onDataRefresh, rowKey, sandboxId, selectedItem, customArgs) {
    try {
      let data = await fetchDirectly({
        token: await getAccessTokenSilently(),
        fullUrl: `v1/org/${selectedTableRow?.[tableRowKey]}/product/${rowKey}`,
        method: "delete",
      });
      fetchChildTableData();
      setSnackBarState(true);
      setSnackBar({ message: "Product deleted successfully", severity: "success" });
      return data;
    } catch (error) {
      console.error("Error message:", error.message);
      setSnackBarState(true);
      setSnackBar({ message: "Error deleting product", severity: "error" });
    }
  }

  async function deleteSandboxHandler(apiName, onDataRefresh, rowKey, sandboxId, selectedItem, customArgs) {
    try {
      let data = await fetchDirectly({
        token: await getAccessTokenSilently(),
        fullUrl: `v1/org/${selectedTableRow?.[tableRowKey]}/product/${selectedChildTableRow?.[childTableRowKey]}/sandbox/${rowKey}`,
        method: "delete",
      });
      fetchGrandChildTableData();
      setSnackBarState(true);
      setSnackBar({ message: "Sandbox deleted successfully", severity: "success" });
      return data;
    } catch (error) {
      console.error("Error message:", error.message);
      setSnackBarState(true);
      setSnackBar({ message: "Error deleting sandbox", severity: "error" });
    }
  }

  async function fetchChildTableData() {
    if (selectedTableRow?.[tableRowKey] !== undefined) {
      try {
        let data = await fetchDirectly({
          token: await getAccessTokenSilently(),
          fullUrl: `v1/org/${selectedTableRow?.[tableRowKey]}/product`,
        });
        setProductData(data);
      } catch (error) {
        console.error("Error message:", error.message);
        console.error("Error details:", error);
      }
    }
  }

  async function fetchGrandChildTableData() {
    if (selectedChildTableRow?.[childTableRowKey] !== undefined) {
      try {
        let data = await fetchDirectly({
          token: await getAccessTokenSilently(),
          fullUrl: `v1/org/${selectedTableRow?.[tableRowKey]}/product/${selectedChildTableRow?.[childTableRowKey]}/sandboxes`,
        });
        setSandboxData(data);
      } catch (error) {
        console.error("Error message:", error.message);
        console.error("Error details:", error);
      }
    }
  }

  useEffect(() => {
    if (permissionData) {
      fetchTableData();
    }
  }, [permissionData]);

  useEffect(() => {
    if (selectedTableRow && selectedTableRow?.[tableRowKey]) {
      fetchChildTableData();
    }
  }, [selectedTableRow]);

  useEffect(() => {
    if (selectedChildTableRow && selectedChildTableRow?.[childTableRowKey]) {
      fetchGrandChildTableData();
    }
  }, [selectedChildTableRow]);

  return isLoading ? (
    <RHLoadingMask />
  ) : (
    <>
      <RHEditableTable
        title={tableFriendlyName}
        friendlyName={tableFriendlyName}
        apiName={tableApi}
        rowKey={"org_id"}
        columns={orderedColumns}
        tableData={orgData}
        initialState={tableInitialState}
        sandbox={selectedSandbox}
        hasEditPermission={hasEditPermission}
        classes={classes}
        setSnackBarState={setSnackBarState}
        setSnackBar={setSnackBar}
        editableInline={false}
        textFieldFilter={helpers.customFilterFunc}
        retrieveSelectedRows={setSelectedTableRow}
        retrieveNewItemAsSelectedRow
        onSelectedRowsChanged={helpers.onSelectedRowsChanged(setSelectedTableRow)}
        newModel={tableEntry}
        newModelArgs={[]}
        updateHandler={updateOrgHandler}
        createHandler={createOrgHandler}
        deleteHandler={deleteOrgHandler}
      />

      {selectedTableRow?.[tableRowKey] && (
        <RHEditableTable
          title={childTableFriendlyName}
          friendlyName={childTableFriendlyName}
          apiName={childTableApi}
          rowKey={"product_id"}
          columns={childOrderedColumns}
          tableData={productData}
          initialState={childTableInitialState}
          sandbox={selectedSandbox}
          hasEditPermission={hasEditPermission}
          classes={classes}
          setSnackBarState={setSnackBarState}
          setSnackBar={setSnackBar}
          editableInline={false}
          textFieldFilter={helpers.customFilterFunc}
          retrieveSelectedRows={setSelectedChildTableRow}
          retrieveNewItemAsSelectedRow
          onSelectedRowsChanged={helpers.onSelectedRowsChanged(setSelectedChildTableRow)}
          newModel={childTableEntry}
          newModelArgs={[selectedTableRow?.[tableRowKey]]}
          updateHandler={updateProductHandler}
          createHandler={createProductHandler}
          deleteHandler={deleteProductHandler}
        />
      )}

      {selectedChildTableRow?.[childTableRowKey] && (
        <RHEditableTable
          title={grandChildTableFriendlyName}
          friendlyName={grandChildTableFriendlyName}
          apiName={grandChildTableApi}
          rowKey={sandboxTableRowKey}
          columns={grandChildOrderedColumns}
          tableData={sandboxData}
          initialState={grandChildTableInitialState}
          sandbox={selectedSandbox}
          hasEditPermission={hasEditPermission}
          classes={classes}
          setSnackBarState={setSnackBarState}
          setSnackBar={setSnackBar}
          editableInline={false}
          textFieldFilter={helpers.customFilterFunc}
          newModel={grandChildTableEntry}
          newModelArgs={[selectedTableRow?.[tableRowKey], selectedChildTableRow?.[childTableRowKey]]}
          customArgs={{ org_id: selectedTableRow?.[orgTableRowKey] }}
          retrieveSelectedRows={helpers.showChildForm}
          updateHandler={updateSandboxHandler}
          createHandler={createSandboxHandler}
          deleteHandler={deleteSandboxHandler}
        />
      )}
      <Snackbar
        className="snackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarState}
        message={snackBar.message}
        severity={snackBar.severity}
        key={"bottom-center"}
        autoHideDuration={3000}
        onClose={() => setSnackBarState(false)}
      />
    </>
  );
}
