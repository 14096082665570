import { Link, useLocation } from "react-router-dom";
import { capitalizeFirstLetters } from "../../common/helpers";
import routeNames from "./routeNames";

export default function BreadCrumbs({ currentStateData }) {
  const location = useLocation();

  const breadcrumbStyle = {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
  };

  const breadcrumbLabelStyle = {
    color: "#ffffff90",
    textTransform: "uppercase",
    marginRight: "5px",
  };

  const breadcrumbLinkStyle = {
    color: "#9d69ff95",
    textDecoration: "none",
    textTransform: "uppercase",
    marginLeft: "0px",
    marginRight: "5px",
  };

  const breadcrumbSeparatorStyle = {
    margin: "0 5px",
    color: "#999",
  };

  function getAdminName() {
    const pathSegments = location.pathname.split("/");
    pathSegments.shift();

    return `${capitalizeFirstLetters(pathSegments[0])} ${
      pathSegments[1] ? " / " + capitalizeFirstLetters(pathSegments[1]) : ""
    } ${pathSegments[2] ? "/ " + routeNames[location.pathname.split("/").pop()] : ""}`;
  }

  return (
    <div style={breadcrumbStyle}>
      {location.pathname.split("/")[1] === "admin" && <span>{getAdminName()}</span>}
      {currentStateData?.product && (
        <span>
          <Link
            style={breadcrumbLinkStyle}
            to={`/org/${currentStateData?.org?.shortName}/product/${currentStateData?.product?.shortName}`}
          >
            <span style={breadcrumbLabelStyle}>Product:</span>
            {currentStateData?.product?.productName}
          </Link>
        </span>
      )}
      {currentStateData?.sandbox && (
        <span>
          <span style={breadcrumbSeparatorStyle}>/</span>
          <Link
            style={breadcrumbLinkStyle}
            to={`/org/${currentStateData?.org?.shortName}/product/${currentStateData?.product?.shortName}/sandbox/${currentStateData?.sandbox?.shortName}`}
          >
            <span style={breadcrumbLabelStyle}>Sandbox:</span>
            {currentStateData?.sandbox?.sandboxName}
          </Link>
        </span>
      )}
      {currentStateData?.environment && (
        <span>
          <span style={breadcrumbSeparatorStyle}>/</span>
          <Link
            style={breadcrumbLinkStyle}
            to={`/org/${currentStateData?.org.shortName}/product/${currentStateData?.product?.shortName}/environment/${currentStateData?.environment?.shortName}`}
          >
            <span style={breadcrumbLabelStyle}>Environment:</span>

            {currentStateData?.environment?.environmentName}
          </Link>
        </span>
      )}
    </div>
  );
}
