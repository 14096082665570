import React, { useState, useRef, useCallback } from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ResizableDrawer({ isOpen, onClose, children }) {
  const [drawerWidth, setDrawerWidth] = useState(60); // Width as a percentage
  const drawerRef = useRef(null);

  const startResize = useCallback(
    (event) => {
      const startX = event.clientX;

      const doResize = (moveEvent) => {
        const currentX = moveEvent.clientX;
        const delta = startX - currentX;
        const newWidth = Math.max(20, Math.min(100, drawerWidth + (delta / window.innerWidth) * 100));

        setDrawerWidth(newWidth);
      };

      const stopResize = () => {
        document.removeEventListener("mousemove", doResize);
        document.removeEventListener("mouseup", stopResize);
      };

      document.addEventListener("mousemove", doResize);
      document.addEventListener("mouseup", stopResize);
    },
    [drawerWidth]
  );

  const drawerStyle = {
    width: `${drawerWidth}%`,
    maxWidth: "100% -264px",
    position: "fixed",
    right: isOpen ? 0 : "-100%",
    top: 104,
    height: "100%",
    backgroundColor: "#1E1E1E",
    transition: "right 0.3s",
    zIndex: 1300,
    color: "white",
    borderLeft: "0px solid #2c2c2c",
    boxShadow: "-4px 0px 8px 0px rgba(0,0,0,0.75)",
  };

  return (
    <Box ref={drawerRef} sx={drawerStyle}>
      <div
        onMouseDown={startResize}
        style={{
          cursor: "ew-resize",
          position: "absolute",
          left: 0,
          top: 0,
          bottom: 0,
          width: "10px",
          zIndex: 1400,
        }}
      />
      {children}
    </Box>
  );
}

export default ResizableDrawer;
