import { Fragment } from "react";

function RHConfirmDialog({ open, onConfirm, onCancel, title, content }) {
  if (!open) return null;

  const breakStyle = { margin: "1px 0" };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50">
      <div className="bg-gray border-2 border-white border-opacity-20 shadow-lg rounded-lg max-w-md w-full">
        <div className="px-6 py-4">
          <h3 className="text-lg font-medium text-white">{title}</h3>
          <p className="text-white font-thin mt-2">
            {content.split("\n").map((line, index) => (
              <Fragment key={index}>
                {line}
                {index < content.split("\n").length - 1 && <br style={breakStyle} />}
              </Fragment>
            ))}
          </p>
        </div>
        <div className="flex justify-end space-x-4 px-6 py-2 bg-gray-800 rounded-b-lg">
          <button
            className="bg-gray-700 hover:bg-white hover:bg-opacity-5 text-white font-bold py-1 px-4 rounded"
            onClick={onCancel}
          >
            No
          </button>
          <button className="bg-purple hover:bg-opacity-80  text-white font-bold py-1 px-4 rounded" onClick={onConfirm}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
}

export default RHConfirmDialog;
