import React from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import _ from "lodash";
import { useState, useEffect } from "react";

const useMultiSelect = (
  defaultValues,
  configPath,
  gameConfig,
  setGameConfig,
  setIsDirty
) => {
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    const valueAtPath = _.get(gameConfig, configPath);
    if (valueAtPath) {
      setSelectedValues(valueAtPath);
    }
  }, [_.get(gameConfig, configPath)]);

  const uniqueValues = [
    ...new Set([...defaultValues, ...(_.get(gameConfig, configPath) || [])]),
  ];

  const setProperty = (obj, path, value) => {
    const [head, ...rest] = path.split(".");

    return {
      ...obj,
      [head]: rest.length
        ? setProperty(obj[head], rest.join("."), value)
        : value,
    };
  };

  const handleChange = (event) => {
    setSelectedValues(event.target.value);
    const { name, value } = event.target;
    const newGameConfig = setProperty(gameConfig, name, value);
    setGameConfig(newGameConfig);
    setIsDirty(true);
  };

  return {
    selectedValues,
    uniqueValues,
    handleChange,
  };
};

const RHMultiSelectDropdown = ({
  label,
  name,
  configPath,
  gameConfig,
  setGameConfig,
  setIsDirty,
  isEditMode,
  defaultValues,
}) => {
  const { selectedValues, uniqueValues, handleChange } = useMultiSelect(
    defaultValues,
    configPath,
    gameConfig,
    setGameConfig,
    setIsDirty
  );

  return (
    <TextField
      label={label}
      name={name}
      value={gameConfig ? _.get(gameConfig, configPath) : ""}
      SelectProps={{
        multiple: true,
        value: selectedValues,
      }}
      helperText={`${label} to request`}
      select
      onChange={handleChange}
      disabled={!isEditMode}
    >
      {uniqueValues.map((scope) => (
        <MenuItem key={scope} value={scope}>
          {scope}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default RHMultiSelectDropdown;
