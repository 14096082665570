import { useRef, useState, useContext, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import { useSandboxStyles } from "../sandbox/styles";
import AccountContext from "../../contexts/AccountContext";
import RHLoadingMask from "../../components/common/RHLoadingMask";
import RHEditableTable from "../../components/common/RHEditableTable";
import { fetchDirectly } from "common/apiUtils";
import { useAuth } from "contexts/AuthContext";

export default function RHProductPolicyPage() {
  const classes = useSandboxStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState();
  const [isEdit, setIsEdit] = useState();
  const [isCancel, setIsCancel] = useState([]);
  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState();

  const selectedPermissions = useRef();
  const prevSelectedPermissions = useRef();
  const permissions = useRef();

  const { currentStateData } = useContext(AccountContext);
  const { getAccessTokenSilently } = useAuth();

  const API_NAME = "policies";
  const ROW_KEY = "policy_id";

  const COLUMNS = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      required: true,
      hideable: false,
    },
    {
      field: "policy_id",
      headerName: "ID",
      flex: 1,
      alwaysDisabled: true,
      type: "metadata",
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      required: true,
    },
    {
      field: "permissions",
      headerName: "Permissions",
      flex: 1,
    },
    {
      field: "user_auth_enable",
      headerName: "User Auth",
      flex: 1,
      type: "boolean",
    },
    {
      field: "client_auth_enable",
      headerName: "Client Auth",
      flex: 1,
      type: "boolean",
    },
    {
      field: "refresh_token_enable",
      headerName: "Refresh Allowed",
      flex: 1,
      type: "boolean",
    },
    {
      field: "access_token_lifetime_seconds",
      headerName: "Access Token Lifetime Seconds",
      flex: 1,
      type: "integer",
    },
    {
      field: "refresh_token_lifetime_days",
      headerName: "Refresh Token Lifetime Days",
      flex: 1,
      type: "integer",
    },
    {
      field: "default_policy",
      headerName: "Default Policy",
      flex: 1,
      type: "boolean",
    },
  ];

  class policyEntry {
    constructor() {}
    permissions = [];
  }

  async function refreshData() {
    const resp = await fetchData();
    return resp;
  }

  async function fetchData() {
    try {
      const productId = currentStateData?.product?.productId;

      const policy_fetch = new Promise(async (resolve) => {
        const resp = await fetchDirectly({
          fullUrl: `v1/product/${productId}/policy`,
          method: "get",
          token: await getAccessTokenSilently(),
        });

        resolve(resp);
      });

      const permission_fetch = new Promise(async (resolve) => {
        const resp = fetchDirectly({
          fullUrl: "v1/permission",
          method: "get",
          token: await getAccessTokenSilently(),
        });

        resolve(resp);
      });

      const resp = await Promise.all([policy_fetch, permission_fetch]).then((responses) => {
        permissions.current = responses[1].permissions;
        setColumns(COLUMNS);
        setIsLoading(false);
        return responses[0];
      });

      setData(resp.policies);

      return resp;
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }

  function customFilterFunc(field) {
    return !["created_timestamp", "permissions", "default_policy"].includes(field.field);
  }

  async function createHandler(apiName, onDataRefresh, sandboxId, editedItem) {
    const prodId = currentStateData?.product?.productId;
    editedItem.permissions = selectedPermissions.current;

    const item = await fetchDirectly({
      fullUrl: `v1/product/${prodId}/policy`,
      method: "post",
      token: await getAccessTokenSilently(),
      body: editedItem,
    });

    fetchData();
    setIsEdit(false);
    return item;
  }

  async function updateHandler(apiName, onDataRefresh, editedItemId, sandboxId, editedItem) {
    const prodId = currentStateData?.product?.productId;
    editedItem.permissions = selectedPermissions.current;
    const item = await fetchDirectly({
      fullUrl: `v1/product/${prodId}/policy/${editedItemId}`,
      method: "put",
      token: await getAccessTokenSilently(),
      body: editedItem,
    });
    fetchData();
    setIsEdit(false);
    return item;
  }

  async function deleteHandler(apiName, onDataRefresh, editedItemId) {
    const prodId = currentStateData?.product?.productId;
    await fetchDirectly({
      fullUrl: `v1/product/${prodId}/policy/${editedItemId}`,
      method: "delete",
      token: await getAccessTokenSilently(),
    });
    fetchData();
    setSelectedRow(null);
  }

  const setPermissionsSelectedProp = (itemPermissionIds) => {
    const newPermissions = permissions.current.map((x) => {
      x.selected = itemPermissionIds.includes(x.permission_id);
      return x;
    });
    permissions.current = newPermissions;
  };

  const handleRetrieveSelectedRow = (item, isEdit) => {
    console.log(item, isEdit);
    setIsCancel(false);
    setSelectedRow(item || {});
    setPermissionsSelectedProp(item?.permissions || []);
    selectedPermissions.current = item?.permissions || [];
    prevSelectedPermissions.current = selectedPermissions.current;
    setIsEdit(isEdit);
  };

  const handleCancel = (isCreateNew) => {
    selectedPermissions.current = prevSelectedPermissions.current;
    setIsCancel(isCreateNew);
    setIsEdit(false);
  };

  const handleEdit = () => {
    setIsEdit(true);
  };

  const handleSelectedRowsChanged = (items) => {
    selectedPermissions.current = items;
  };

  const initialState = {
    pagination: {
      paginationModel: {
        pageSize: 5,
      },
    },
    columns: {
      columnVisibilityModel: {
        policy_id: false,
      },
    },
    sorting: {
      sortModel: [{ field: "name", sort: "asc" }],
    },
  };

  const permissionsInitialState = {
    pagination: {
      paginationModel: {
        pageSize: 5,
      },
    },
    columns: {
      columnVisibilityModel: {
        selected: false,
      },
    },
    sorting: {
      sortModel: [{ field: "selected", sort: "desc" }],
    },
  };

  const overrideDetailTitle = (key) => {
    const policies = data;
    const policy = policies.find((p) => p.policy_id === key);
    const title = "Policy Editor";
    const editTitle = policy && policy.default_policy ? `${title} (read-only)` : `${title}`;
    return key ? editTitle : `New ${title}`;
  };

  useEffect(() => {
    if (currentStateData?.product?.productId) {
      fetchData();
    }
  }, [currentStateData]);

  return isLoading ? (
    <RHLoadingMask />
  ) : (
    <>
      <RHEditableTable
        apiName={API_NAME}
        title="Policy Editor"
        friendlyName="Policy"
        friendlyNameKey="name"
        rowKey={ROW_KEY}
        columns={columns}
        tableData={data}
        initialState={initialState}
        hasEditPermission={
          currentStateData?.product?.permissions?.includes("globalAdmin:*:*") ||
          currentStateData?.product?.permissions?.includes("policies:config:edit")
        }
        onDataRefresh={refreshData}
        createHandler={createHandler}
        updateHandler={updateHandler}
        deleteHandler={deleteHandler}
        onEdit={handleEdit}
        onCancel={handleCancel}
        textFieldFilter={customFilterFunc}
        classes={classes}
        setSnackBarState={setSnackBarState}
        setSnackBar={setSnackBar}
        retrieveSelectedRows={handleRetrieveSelectedRow}
        retrieveNewItemAsSelectedRow
        editableInline={false}
        newModel={policyEntry}
        newModelArgs={[]}
        isRowEditable={(row) => !row.default_policy}
        detailTitleOverride={overrideDetailTitle}
      />
      {selectedRow && !isCancel && (
        <RHEditableTable
          apiName={"permissions"}
          title="Permission"
          rowKey="permission_id"
          hideSearchBoxTitle
          checkboxSelection
          disableRowClick
          columns={[
            {
              field: "permission_id",
              headerName: "Permissions",
              flex: 1,
            },
            {
              field: "description",
              headerName: "Description",
              flex: 1,
            },
            {
              field: "selected",
              headerName: "selected",
              flex: 1,
              type: "boolean",
            },
          ]}
          tableData={permissions.current}
          isRowSelectable={() => isEdit}
          hasEditPermission={
            currentStateData?.product?.permissions?.includes("globalAdmin:*:*") ||
            currentStateData?.product?.permissions?.includes("policies:config:edit")
          }
          onSelectedRowsChanged={handleSelectedRowsChanged}
          selectionModel={selectedPermissions.current}
          parentRowKey={ROW_KEY}
          initialState={permissionsInitialState}
          classes={classes}
          setSnackBarState={setSnackBarState}
          setSnackBar={setSnackBar}
          hideHeader={true}
          newModel={policyEntry}
          newModelArgs={[]}
          hideButtons
        />
      )}
      <Snackbar
        className="snackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarState}
        message={snackBar.message}
        severity={snackBar.severity}
        key={"bottom-center"}
        autoHideDuration={3000}
        onClose={() => setSnackBarState(false)}
      />
    </>
  );
}
