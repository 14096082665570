import * as React from "react";
import splashLogo from "../../assets/rally-here-R-logo-400x400.png";
import { CircularProgress, Typography, Box } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error"; // Import Error Icon from Material UI Icons

export default function RHDynamicLoadingMask({ loadMessage = "Looking for data...", showError = false }) {
  const iconSize = 50; // Maintain consistent icon size for loading and error

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        bgcolor: "background.default",
        textAlign: "center",
        height: "100vh",
        color: "white",
        gap: 2, // Consistent space between spinner/error icon and message
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {showError ? (
          <ErrorIcon sx={{ fontSize: iconSize + 5, color: "error.main" }} />
        ) : (
          <>
            <CircularProgress size={iconSize + 5} />
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 37.5, // Half the size of the spinner
                height: 37.5, // Same as width to maintain aspect ratio
                borderRadius: "50%", // Make it circular
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box component="img" src={splashLogo} alt="Logo" sx={{ width: "100%" }} />
            </Box>
          </>
        )}
      </Box>
      <Typography
        variant="subtitle1" // A smaller, subtler variant compared to 'h6'
        sx={{
          maxWidth: "80%", // Prevent the message from being too wide on large screens
          color: showError ? "error.contrastText" : "white", // Change text color based on error state
          fontWeight: "normal", // Set to 'normal' instead of 'bold'
          letterSpacing: "0.00938em", // Standard letter-spacing for 'subtitle1' variant
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', // A typical sharp font family
          lineHeight: 2, // Adjust line height for better readability
        }}
      >
        {loadMessage}
      </Typography>
    </Box>
  );
}
