import { SHA256 } from "crypto-js";

export function generateColor(text) {
  const hash = SHA256(text).toString();

  // Generate a single color based on the hash
  const baseColor = `#${hash.substring(0, 6)}`;

  // Define shades of the base color
  const color1 = adjustBrightness(baseColor, -80); // Darker shade
  const color2 = adjustBrightness(baseColor, 20); // Lighter darker shade

  return `linear-gradient(45deg, ${color1}, ${color2})`;
}

function adjustBrightness(color, amount) {
  const colorValue = color.substring(1); // Remove the '#'
  const num = parseInt(colorValue, 16);
  const r = (num >> 16) + amount;
  const g = ((num >> 8) & 255) + amount;
  const b = (num & 255) + amount;
  const cappedR = r > 255 ? 255 : r < 0 ? 0 : r;
  const cappedG = g > 255 ? 255 : g < 0 ? 0 : g;
  const cappedB = b > 255 ? 255 : b < 0 ? 0 : b;
  const adjustedColor = "#" + ((cappedR << 16) | (cappedG << 8) | cappedB).toString(16).padStart(6, "0");
  return adjustedColor;
}
