import { createContext, useState, useContext, useEffect } from "react";
import definitions from "../../common/definitions.json";
import pageConfig from "../../common/pageConfig.json";

const DataContext = createContext();

export const useDataContext = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [dataContext, setDataContext] = useState({
    schemaObjects: {},
  });

  // Function to load tableSettings
  const loadTableSettings = () => {
    const tableSettings = JSON.parse(localStorage.getItem("tableSettings")) || {};
    return tableSettings;
  };

  const updateSchemaObjects = (tableSettings) => {
    const schemaObjects = loadSchemaObjects(tableSettings);
    setDataContext((prevState) => ({
      ...prevState,
      schemaObjects: schemaObjects,
    }));
  };

  // Function to load and transform pageConfig into schema objects
  const loadSchemaObjects = (tableSettings) => {
    const schemaObjects = {};

    // First pass: Create schema objects without the fetchAllEndpoint
    Object.entries(pageConfig).forEach(([schemaKey, schemaValue]) => {
      const baseStateKey = `${schemaValue.base}State`;
      const savedState = tableSettings[baseStateKey];

      const schemaDefinitions = definitions.schemas[schemaValue.base] || {};

      const { endpoints, ...columnsDefinitions } = schemaDefinitions;

      const columns = Object.entries(columnsDefinitions).reduce((acc, [columnKey, columnValue]) => {
        // Exclude 'endpoints' property from columns
        if (columnKey !== "endpoints") {
          acc[columnKey] = {
            ...columnValue,
          };
        }
        return acc;
      }, {});

      schemaObjects[schemaKey] = {
        ...schemaValue,
        visibleColumns: savedState?.visibleColumns || schemaValue.visibleColumns || ["last_modified_timestamp"],
        paginationModel: savedState?.paginationModel || { pageSize: 10, page: 0 },
        sortModel: savedState?.sortModel || [{ field: "last_modified_timestamp", sort: "desc" }],
        columns: columns,
        endpoints: endpoints,
      };

      if (schemaValue.sortOptions) {
        const sortOptionsKey = `${schemaValue.base}SortOptions`;
        const sortOptionsSchema = definitions.schemas[sortOptionsKey];
        if (sortOptionsSchema && sortOptionsSchema["enum_var_names"]) {
          schemaObjects[schemaKey].sortOptions = sortOptionsSchema["enum_var_names"];
        }
      } else {
        schemaObjects[schemaKey].sortOptions = [];
      }
    });

    // Second pass: Assign fetchAllEndpoint based on tableData
    Object.entries(schemaObjects).forEach(([schemaKey, schemaValue]) => {
      if (schemaValue.tableData) {
        const targetSchema = definitions.schemas[schemaValue.tableData];
        if (targetSchema && targetSchema.endpoints) {
          const getEndpoints = targetSchema.endpoints.filter((e) => e.method === "get");

          let fetchAllEndpoint = null;
          if (schemaValue.base === "TransactionData" && schemaValue.version) {
            const version = schemaValue.version.replace(/\/$/, ""); // Remove trailing slash if exists
            fetchAllEndpoint = getEndpoints.find((endpoint) =>
              endpoint.path.replace(/\/$/, "").endsWith(`/${version}`)
            );
          } else {
            fetchAllEndpoint = getEndpoints.length > 0 ? getEndpoints[0] : null;

            // UGH
            if (
              schemaValue.tableData === "AllMatchMakingTemplatesResponse" ||
              schemaValue.tableData === "AllMatchMakingRulesResponse"
            ) {
              fetchAllEndpoint = getEndpoints.length > 1 ? getEndpoints[1] : null;
            }
          }

          schemaObjects[schemaKey].fetchAllEndpoint = fetchAllEndpoint;
        }
      }
    });

    return schemaObjects;
  };

  const manualUpdateSchemaObjects = () => {
    const tableSettings = loadTableSettings(); // Load the latest table settings
    const schemaObjects = loadSchemaObjects(tableSettings); // Generate schema objects
    setDataContext((prevState) => ({
      ...prevState,
      schemaObjects: schemaObjects, // Update the state with new schema objects
    }));
  };

  // Initial load of schema objects
  useEffect(() => {
    const tableSettings = loadTableSettings();
    updateSchemaObjects(tableSettings);
  }, []);

  // Listen for changes in localStorage to update table settings
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === "tableSettings") {
        const newTableSettings = JSON.parse(e.newValue) || {};
        updateSchemaObjects(newTableSettings);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return <DataContext.Provider value={{ dataContext, manualUpdateSchemaObjects }}>{children}</DataContext.Provider>;
};
