import { useState, useContext } from "react";
import { useAuth } from "contexts/AuthContext";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import RHDrawer from "../components/common/Drawer/RHDrawer.js";
import AppBar from "../components/AppBar";
import Menu from "../components/Menu/index.js";
import AccountContext from "../contexts/AccountContext";
import RHMendableFloatingButton from "../components/common/RHMendableFloatingButton";

export default function Layout({ children }) {
  const [menuOpen, setMenuOpen] = useState(true);
  const { permissionData } = useContext(AccountContext);
  const { logout } = useAuth();

  return permissionData?.permissions.length === 0 && !permissionData?.isGlobalAdmin ? (
    <Box>
      <Dialog open={true} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">No Organizations or Permissions Available</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            No Organizations with permissions are available, please contact your administrator.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logout}>Logout</Button>
        </DialogActions>
      </Dialog>
    </Box>
  ) : (
    <div className="block relative">
      <Menu parentMenuOpen={setMenuOpen} forceMenu={menuOpen} />
      <div className={`relative transition-all ${menuOpen ? "lg:w-sans-menu lg:left-menu" : "lg:w-full lg:left-0"}`}>
        <AppBar menuOpen={menuOpen} />
        <div className={`border-box p-8 min-w-0 w-full`}>{children}</div>
      </div>
      <div
        className={`fixed w-full h-full top-0 left-0 overflow-hidden pointer-events-none bg-black/50 z-20 opacity-0 lg:opacity-0 lg:pointer-events-none ${
          menuOpen ? "opacity-90 pointer-events-auto" : "lg:opacity-0"
        }`}
      />
      <RHDrawer />
    </div>
  );
}
