import { useRef, useState, useContext, useEffect } from "react";
import { TextField, FormControl, Autocomplete, Tooltip, Box, Modal, Typography, Button, Checkbox } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { fetchDirectly } from "common/apiUtils";
import { useAuth } from "contexts/AuthContext";
import RHComponentLoader from "./RHComponentLoader";
import { useSandboxStyles } from "../../views/sandbox/styles";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DataGridIcon from "@mui/icons-material/Visibility";
import RHFieldWrapper from "./RHFieldWrapper";
import { debounce } from "../../common/helpers";

// Adding this because I can't duck type the API response if it has an empty hosts array
const STRING_ARRAY_TYPES = ["armada"];

export default function RHEnhancedAutocompleteField(props) {
  const {
    name,
    label,
    required,
    isDynamic,
    api,
    idKey,
    displayValue,
    responseDataName,
    defaultOptions,
    multiSelect,
    value,
    disabled,
    sandboxId,
    onChange,
    sx,
    advancedSearch,
    fullUrl,
    filterOnClient,
    row_key,
    description,
  } = props;

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const { getAccessTokenSilently } = useAuth();

  const [options, setOptions] = useState([]);
  const [optionsInitialized, setOptionsInitialized] = useState(false);
  const [noDataAvailable, setNoDataAvailable] = useState(false);

  let allOptions = useRef([]);
  let arrayContentType = useRef();
  const [isDataReady, setIsDataReady] = useState(false);
  const [val, setVal] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const classes = useSandboxStyles();
  const [data, setData] = useState([]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    paddingBottom: "20px",
    paddingTop: "20px",
  };

  const columns = [
    { field: "id", title: "id", width: 200 },
    { field: "title", title: "title", width: 200 },
  ];

  //helper functions

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  function alphabetizeNames(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  const onValueChange = (event, newValue) => {
    const formattedValue =
      arrayContentType.current === "string" && multiSelect
        ? newValue.map((item) => item.id)
        : multiSelect
        ? newValue?.map((item) => ({ [idKey]: item?.id ?? "" })) ?? []
        : newValue?.id ?? "";

    const newEvent = {
      target: {
        name,
        value: formattedValue ?? (newValue !== "" ? newValue : null),
      },
    };

    onChange(newEvent);
    setVal(newValue);
  };

  let count = {};
  const mapResponseData = (data) => {
    const title = data?.name ?? data?.[displayValue];

    if (count[title]) {
      count[title]++;
    } else {
      count[title] = 1;
    }

    const uniqueTitle =
      (title === undefined && count[title] >= 1) || count[title] > 1 ? `${title}_${count[title]}` : title;

    return {
      title: uniqueTitle, // Now this should be unique
      id: data[idKey],
    };
  };

  async function formatFetchedOptions(response) {
    const targetData = responseDataName;
    const noDataNode = [
      "rulesets",
      "groups",
      "map_selection_lists",
      "maps",
      "partitions",
      "profiles",
      "profile_lists",
      "pools",
      "roles",
      "ranks",
      "strides",
      "instance_request_templates",
      "partitions",
    ];

    if (!targetData) {
      return response?.sort(alphabetizeNames).map((x) => mapResponseData(x)) || [];
    }
    if (noDataNode.includes(targetData)) {
      return response?.[targetData].sort(alphabetizeNames).map((x) => mapResponseData(x)) || [];
    }

    return response.data.sort(alphabetizeNames).map((x) => mapResponseData(x));
  }

  const initializeOptions = async () => {
    let fetchedOptions = [];

    try {
      if (isDynamic) {
        const response = await fetchOptionsDirectly();

        if (response === null || response.length === 0) {
          // Friendly message when no data is returned
          console.log("No options are currently available.");
          setOptions([]);
          return; // Exit early as there are no options to process
        }

        fetchedOptions = await formatFetchedOptions(response);
      } else {
        if (Array.isArray(defaultOptions)) {
          fetchedOptions = defaultOptions;
        } else {
          console.error("defaultOptions should be an array");
          fetchedOptions = [];
        }
      }

      if (advancedSearch) {
        const optionsWithSearch = [{ title: "Advanced Search", id: -1 }, ...fetchedOptions];
        setOptions(optionsWithSearch);
      } else {
        allOptions.current = fetchedOptions;
        setOptions(fetchedOptions);
      }
    } catch (error) {
      console.error("Error fetching options: ", error);
      setOptions([]);
    }
  };

  const initializeValue = async () => {
    let matches = multiSelect ? [] : {};
    let ids;
    if (multiSelect && value.length > 0 && typeof value[0] === "object") {
      ids = isDynamic ? value?.map((v) => v[idKey] ?? "") : value;
    } else {
      ids = value;
    }

    if (multiSelect) {
      if (Array.isArray(ids) && ids.length !== 0) {
        matches = ids
          .map((id) => {
            const option = allOptions.current
              ? allOptions.current.find((opt) => opt.id === id)
              : options.find((opt) => opt.id === id);
            return option ? { title: option.title, id: option.id } : null;
          })
          .filter(Boolean);
      }
    } else if (arrayContentType.current === "string" && filterOnClient) {
      matches = allOptions.current.find((option) => option.id === ids) || {};
    } else {
      matches = options.find((option) => option.id === ids) || {};
    }

    if (!value || !options) {
      setVal([]);
      return;
    } else {
      if (options.length < allOptions.current.length) {
        setOptions(allOptions.current);
        setVal(matches);
      }
      setVal(matches);
    }
  };

  async function getServerOptionsDirectly(inputValue) {
    if (inputValue === "") {
      inputValue = "*";
    }

    if (inputValue) {
      const response = await fetchDirectly(
        {
          endpoint: api,
          method: "get",
          sandboxId: sandboxId,
          token: await getAccessTokenSilently(),
        },
        {
          name: inputValue,
        }
      );
      const newOptions = await formatFetchedOptions(response, inputValue);
      const currentValues = Array.isArray(val) ? val : [val];

      const uniqueOptions = removeDuplicates([...currentValues, ...newOptions]);

      setOptions(uniqueOptions);
    }
  }

  async function fetchOptionsDirectly() {
    const args = {
      method: "GET",
      token: await getAccessTokenSilently(),
      ...(fullUrl ? { fullUrl } : { endpoint: api, sandboxId }),
      dataId: value == null ? undefined : value,
    };

    const createParams = (val) => {
      return Array.isArray(val) ? val.map((v) => ({ [`${idKey}s`]: v[idKey] })) : { [`${idKey}s`]: val };
    };

    const params = value ? createParams(value) : null;

    const response = await fetchDirectly(args, params);

    // Check for successful response with no data
    // For Armada, the data is returned as an array, rather than an array in a .data or .{responseDataName} node
    if (
      (Array.isArray(response) && response.length === 0) ||
      (!Array.isArray(response) && response?.[responseDataName ?? "data"].length === 0)
    ) {
      setNoDataAvailable(true);
      return null; // or appropriate value indicating no data
    }

    return response;
  }

  const updateServerOptions = debounce((event, inputValue, reason) => {
    if (!inputValue || inputValue === "") setOptions(allOptions.current);
    if (inputValue && isDynamic) {
      if (filterOnClient) {
        const filteredOptions = allOptions.current.filter((option) =>
          option.title ? option.title.toLowerCase().includes(inputValue.toLowerCase()) : false
        );
        setOptions(filteredOptions);
      } else getServerOptionsDirectly(inputValue);
    } else if (reason === "clear" && isDynamic && !filterOnClient) {
      getServerOptionsDirectly("");
    }
  }, 200);

  const removeDuplicates = (array) => {
    const map = new Map();
    array.forEach((item) => {
      if (item && item.id != null && !map.has(item.id)) {
        map.set(item.id, item);
      }
    });
    return Array.from(map.values());
  };

  //Component Functions
  const filterUniqueOptions = (options, params) => {
    let filteredOptions = [];

    if (options && params && typeof params.inputValue === "string") {
      filteredOptions = options.filter((option) =>
        option.title ? option.title.toLowerCase().includes(params.inputValue.toLowerCase()) : false
      );
    }

    if (isDynamic) {
      const currentValues = Array.isArray(val) ? val : [val];
      return removeDuplicates([...currentValues, ...options]);
    } else {
      return filteredOptions;
    }
  };

  const handleSelect = (event, option) => {
    if (option && option.title === "Advanced Search") {
      handleOpenModal();
      event.preventDefault();
    }
  };

  const renderOption = (props, option, { selected }) => {
    if (option.id === -1) {
      return (
        <li
          {...props}
          onClick={handleOpenModal}
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            display: "flex",
            alignItems: "center",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.6)",
          }}
        >
          <DataGridIcon style={{ marginRight: "8px" }} />
          Advanced Search from Datatable
        </li>
      );
    }

    if (multiSelect) {
      return (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.title}
        </li>
      );
    } else {
      return <li {...props}>{option.title}</li>;
    }
  };

  const isOptionEqualToValue = (currOption, currValue) => {
    if (!currOption || !currValue || (Array.isArray(currValue) && currValue.length === 0 && multiSelect)) {
      return true;
    }
    if (currOption?.id === -1) {
      return currOption.title === currValue.title;
    }

    if (arrayContentType.current === "string") {
      return currOption.id === currValue || (multiSelect && value.includes(currOption.id));
    }

    return currOption.id === currValue.id;
  };

  // Initialize functions
  useEffect(() => {
    if (
      (value && Array.isArray(value) && typeof value[0] === "string") ||
      api?.includes(STRING_ARRAY_TYPES) ||
      fullUrl?.includes(STRING_ARRAY_TYPES)
    ) {
      arrayContentType.current = "string";
    }
  }, [value, api, fullUrl]);

  useEffect(() => {
    //console.log("Options", options);
    //console.log("Value", value);
    //console.log("Val", val);
    if (options.length !== 0) {
      setIsDataReady(true);
      setNoDataAvailable(false);
    }
  }, [options, value, val]);

  useEffect(() => {
    //A New Row has been selected
    setOptionsInitialized(false);
    initializeOptions().then(() => {
      setOptionsInitialized(true);
      if (!isDynamic) {
        initializeValue();
      }
    });
  }, [row_key]);

  useEffect(() => {
    if (optionsInitialized) {
      initializeValue();
    }
  }, [optionsInitialized]);

  useEffect(() => {
    const originalWarn = console.warn.bind(console);
    console.warn = (msg) => {
      if (msg.includes("MUI: The value provided to Autocomplete is invalid")) {
        return;
      }
      originalWarn(msg);
    };

    return () => {
      console.warn = originalWarn;
    };
  }, []);

  if (!isDataReady) {
    if (noDataAvailable) {
      return (
        <div style={{ marginLeft: "-9px", marginBottom: "5px" }}>
          <RHFieldWrapper label={props.label} inactive={props.inactive} required={required}>
            <div style={{ marginTop: "10px" }}>No Data Available</div>
          </RHFieldWrapper>
        </div>
      );
    }
    return <RHComponentLoader size={25} label={label} />;
  }
  return (
    <>
      <FormControl sx={sx}>
        <Tooltip title={`Search ${label} - ${description}`} placement="left">
          <Autocomplete
            filterOptions={filterUniqueOptions}
            options={options}
            value={val}
            onSelect={handleSelect}
            renderOption={renderOption}
            multiple={multiSelect}
            isOptionEqualToValue={isOptionEqualToValue}
            onInputChange={updateServerOptions}
            onChange={onValueChange}
            getOptionLabel={(option) => option?.title ?? ""}
            disabled={disabled}
            selectOnFocus
            handleHomeEndKeys
            required={required}
            disableCloseOnSelect={multiSelect}
            renderInput={(params) => <TextField {...params} required={required} label={label} />}
          />
        </Tooltip>
      </FormControl>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            style={{
              fontSize: "24px",
              color: "#fff",
              cursor: "pointer",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              marginBottom: "10px",
            }}
          >
            Select XP Tables
          </Typography>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
              className={classes.table}
              rows={data}
              columns={columns}
              pageSize={5}
              filterMode="server"
              onFilterModelChange={(filterModel) => onFilterChangeHandler(filterModel, onFilterChanged)}
              filter
            />
          </div>
          <Box style={{ display: "flex", justifyContent: "flex-end", marginBottom: "16px", marginTop: "16px" }}>
            <Button onClick={() => setIsOpen(false)} variant="contained">
              Select
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
