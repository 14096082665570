import { useState, useContext, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { debounce } from "../../common/helpers";
import SandboxConfigMenu from "./SandboxConfigMenu";
import AccountContext from "../../contexts/AccountContext";
import SubMenu from "./SubMenu";
import logo from "../../assets/rally-here-logo.png";
import {
  CorporateFareIcon,
  CloudIcon,
  EsportsIcon,
  StorageIcon,
  MenuBookIcon,
  DownloadIcon,
  RoomPreferencesIcon,
  MenuExpandIcon,
  FingerPrintIcon,
  CollectionsIcon,
  HelpIcon,
} from "../../assets/svgs";

const DOCS_LINK =
  process.env.NODE_ENV === "production"
    ? "https://rallyhere.gg/docs/"
    : `${process.env.REACT_APP_API_BASE_PATH}/`.replace("api", "docs").replace("developer.", "");

const SUPPORT_LINK = "https://rallyhere.atlassian.net/servicedesk/customer/portals";

export default function Menu({ parentMenuOpen }) {
  const history = useHistory();

  const [activeMenuElem, setActiveMenuElem] = useState();
  const [activeOrgs, setActiveOrgs] = useState();
  const [menuOpen, setMenuOpen] = useState(window.innerWidth > 1024);
  const [environmentMenu, setEnvironmentMenu] = useState([]);
  let isMobileSize = useRef(window.innerWidth <= 1024);

  const { orgsData, permissionData, currentStateData } = useContext(AccountContext);

  function toggleMenu() {
    parentMenuOpen && parentMenuOpen((prev) => !prev);
    setMenuOpen((prev) => !prev);
  }

  function manageBrowserSize(size) {
    if (size === "desktop" && isMobileSize.current) {
      parentMenuOpen && parentMenuOpen(true);
      setMenuOpen(true);
      isMobileSize.current = false;
    } else if (size === "mobile" && !isMobileSize.current) {
      parentMenuOpen && parentMenuOpen(false);
      setMenuOpen(false);
      isMobileSize.current = true;
    }
  }

  function hasAnyPermission(hasPermissions, needsPermissions) {
    needsPermissions.push("globalAdmin:*:*");
    return hasPermissions && hasPermissions.some((permission) => needsPermissions.includes(permission));
  }

  useEffect(() => {
    setActiveMenuElem(history.location.pathname);
  }, [history.location.pathname]);

  useEffect(() => {
    if (!orgsData) return;

    const activeOrgs = Object.values(orgsData).filter((x) => !x.archive);

    setActiveOrgs(activeOrgs);
  }, [orgsData]);

  useEffect(() => {
    if (permissionData?.isGlobalAdmin) {
      const menuHeight = document.getElementById("admin-menu").scrollHeight;
      document.getElementById("main-menu").style.paddingBottom = `${menuHeight + 20}px`;
    }
  }, [permissionData]);

  useEffect(() => {
    parentMenuOpen && parentMenuOpen(window.innerWidth > 1024);
    window.addEventListener(
      "resize",
      debounce(() => {
        manageBrowserSize(window.innerWidth > 1024 ? "desktop" : "mobile");
      }, 75)
    );

    return window.removeEventListener(
      "resize",
      debounce(() => {
        manageBrowserSize(window.innerWidth > 1024 ? "desktop" : "mobile");
      }, 75)
    );
  }, []);

  useEffect(() => {
    let envMenuData = [];
    if (currentStateData?.environment) {
      envMenuData = [];
      if (
        hasAnyPermission(currentStateData?.environment?.permissions, [
          "environment:config:view",
          "environment:config:edit",
        ])
      ) {
        envMenuData.push({
          shortName: "options",
          environment_settingsName: "Options",
          environment_settingsId: "options",
        });
      }
      if (
        hasAnyPermission(currentStateData?.environment?.permissions, [
          "environment:support:view",
          "environment:config:view",
          "environment:config:edit",
        ])
      ) {
        envMenuData.push({
          shortName: "legacy_dev_portal",
          environment_settingsName: "Legacy Dev Portal",
          environment_settingsId: "legacy_dev_portal",
          externalLink: currentStateData?.environment?.environmentClassicSupportToolUrl,
          tokenQueryParam: "authToken",
        });
      }
      if (hasAnyPermission(currentStateData?.environment?.permissions, ["environment:analytics:view"])) {
        let baseUrl = currentStateData?.environment?.baseUrl;
        if (!baseUrl.startsWith("https://")) {
          baseUrl = `https://${baseUrl}`;
        }
        envMenuData.push({
          shortName: "insights",
          environment_settingsName: "Insights",
          environment_settingsId: "insights",
          externalLink: `${baseUrl}/grafana/dashboards`,
          tokenQueryParam: "auth_token",
        });
      }
    }
    setEnvironmentMenu(envMenuData);
  }, [currentStateData?.environment]);

  return (
    <div
      className={`fixed h-full transition-all -translate-x-full overflow-x-visible flex-1 ${
        menuOpen ? "translate-x-0 w-menu" : "w-0"
      } transition-all z-30`}
    >
      <div
        className={`absolute ${
          !menuOpen ? "-right-9" : "right-3"
        } top-3 z-40 p-2 cursor-pointer rounded border-white/40 border flex items-center justify-center transition-all`}
        onClick={toggleMenu}
      >
        <MenuExpandIcon width="8" classes={`${!menuOpen ? "rotate-0" : "rotate-180"}`} />
      </div>
      <div className="bg-gray h-full max-w-menu w-full border-box top-0 left-0 z-10 text-fontBase overflow-y-scroll fixed">
        <Link to="/" className="flex align-center">
          <img className="w-full max-w-[140px] ml-3.5 px-2 py-[0.6rem] my-1" src={logo} alt="Rally Here Logo" />
        </Link>
        {permissionData && orgsData && (
          <>
            <div id="main-menu">
              {activeOrgs && (location.pathname.includes("/org/") || location.pathname.includes("/tools")) && (
                <SubMenu
                  linkPrefix={"/org"}
                  name={"Organizations"}
                  segmentName={"org"}
                  selected={currentStateData?.org !== undefined}
                  showSelected
                  data={activeOrgs}
                />
              )}
              {currentStateData?.org &&
                !currentStateData?.product &&
                hasAnyPermission(currentStateData?.org?.permissions, [
                  "accountOrg:config:edit",
                  "accountOrg:config:view",
                ]) && (
                  <>
                    <Link to={`/org/${currentStateData?.org?.shortName}/account-management`} className="menu-link mt-2">
                      <span
                        className={`px-2 py-1 transition-colors w-full rounded ${
                          location.pathname.includes("/account-management")
                            ? "text-white bg-selectedPurple hover:bg-selectedPurple"
                            : "hover:bg-white/10"
                        }`}
                      >
                        <RoomPreferencesIcon classes="inline-block mr-2" />
                        <span>User Account Management</span>
                      </span>
                    </Link>
                    <div className="h-[1px] w-full mt-2">
                      <div className="h-full mx-4 bg-white/10"></div>
                    </div>
                  </>
                )}
              {currentStateData?.org && (
                <SubMenu
                  linkPrefix={`/org/${currentStateData?.org.shortName}/product`}
                  name={"Products"}
                  segmentName={"product"}
                  selected={currentStateData?.product !== undefined}
                  showSelected
                  data={orgsData[currentStateData?.org.shortName]?.products}
                  icon={<EsportsIcon classes={`inline-block mr-2`} />}
                />
              )}
              {currentStateData?.product && !currentStateData?.sandbox && !currentStateData?.environment && (
                <SubMenu
                  linkPrefix={`/org/${currentStateData?.org.shortName}/product/${currentStateData?.product.shortName}`}
                  name={"Credentials"}
                  segmentName={"credentials"}
                  isAdmin
                  data={[
                    {
                      shortName: "policies",
                      credentialsName: "Policies",
                      credentialsId: "policies",
                    },
                    {
                      shortName: "clients",
                      credentialsName: "Clients",
                      credentialsId: "clients",
                    },
                  ]}
                  icon={<FingerPrintIcon classes={`inline-block mr-2`} />}
                />
              )}
              {currentStateData?.product &&
                !currentStateData?.sandbox &&
                currentStateData?.product?.environments.filter((x) => !x.archive).length > 0 && (
                  <SubMenu
                    linkPrefix={`/org/${currentStateData?.org.shortName}/product/${currentStateData?.product?.shortName}/environment`}
                    name={"Environments"}
                    segmentName={"environment"}
                    selected={currentStateData?.environment !== undefined}
                    showSelected
                    data={
                      orgsData[currentStateData?.org.shortName]?.keyedProducts?.[currentStateData?.product.shortName]
                        ?.environments
                    }
                    icon={<CollectionsIcon classes={`inline-block mr-2`} />}
                  />
                )}
              {currentStateData?.environment && (
                <SubMenu
                  linkPrefix={`/org/${currentStateData?.org.shortName}/product/${currentStateData?.product?.shortName}/environment/${currentStateData?.environment?.shortName}`}
                  name={"Environment Settings"}
                  segmentName={"environment_settings"}
                  isAdmin
                  data={environmentMenu}
                />
              )}
              {currentStateData?.product &&
                currentStateData?.product.sandboxes.length > 0 &&
                !currentStateData?.environment && (
                  <SubMenu
                    linkPrefix={`/org/${currentStateData?.org.shortName}/product/${currentStateData?.product.shortName}/sandbox`}
                    name={"Sandboxes"}
                    segmentName={"sandbox"}
                    selected={currentStateData?.sandbox !== undefined}
                    showSelected
                    data={
                      orgsData[currentStateData?.org.shortName]?.keyedProducts?.[currentStateData?.product.shortName]
                        ?.sandboxes
                    }
                    icon={<StorageIcon classes={`inline-block mr-2`} />}
                  />
                )}
              {currentStateData?.sandbox && (
                <>
                  <h3 className="text-white font-bold text-sm uppercase mt-3 mb-1 px-6 py-1 opacity-40">
                    Sandbox Configuration
                  </h3>
                  <SandboxConfigMenu />
                  <div className="h-[1px] w-full mt-2">
                    <div className="h-full mx-4 bg-white/10" />
                  </div>
                </>
              )}
              <div className="mt-2">
                {location.pathname.includes("/admin") && (
                  <>
                    <SubMenu
                      linkPrefix={"/admin"}
                      name={"Admin"}
                      segmentName={"admin"}
                      selected={true}
                      icon={<CorporateFareIcon classes={`inline-block mr-2`} />}
                      isAdmin
                      data={[
                        {
                          shortName: "dashboard",
                          adminName: "Dashboard",
                          adminId: "dashboard",
                        },
                        {
                          shortName: "orgstructure",
                          adminName: "Org Structure",
                          adminId: "orgstructure",
                        },
                        {
                          shortName: "permissions",
                          adminName: "Permissions",
                          adminId: "permissions",
                        },
                      ]}
                    />
                    {permissionData.isGlobalAdmin && (
                      <SubMenu
                        linkPrefix={"/admin/armada"}
                        name={"Armada"}
                        segmentName={"armada"}
                        selected={true}
                        icon={<CloudIcon classes={`inline-block mr-2`} />}
                        isAdmin
                        data={[
                          {
                            shortName: "geo-location",
                            armadaName: "Geo Location",
                            armadaId: "geo-location",
                          },
                          {
                            shortName: "dc-provider",
                            armadaName: "Datacenter Providers",
                            armadaId: "dc-provider",
                          },
                          {
                            shortName: "dc-location",
                            armadaName: "Datacenter Locations",
                            armadaId: "dc-location",
                          },
                          {
                            shortName: "dc-contract",
                            armadaName: "Datacenter Contracts",
                            armadaId: "dc-contract",
                          },
                          {
                            shortName: "machine-image",
                            armadaName: "Machine Images",
                            armadaId: "machine-image",
                          },
                          {
                            shortName: "machine-class",
                            armadaName: "Machine Classes",
                            armadaId: "machine-class",
                          },
                          {
                            shortName: "product-assignment",
                            armadaName: "Simple Product Assignments",
                            armadaId: "product-assignment",
                          },
                          {
                            shortName: "host",
                            armadaName: "Hosts",
                            armadaId: "host",
                          },
                        ]}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
            <div
              id="admin-menu"
              className="bg-neutral-800 fixed bottom-0 left-0 w-full max-w-menu border-t border-fontBase/10 overflow-hidden pb-4"
            >
              <a href={SUPPORT_LINK} className="menu-link" target="_blank">
                <span className="px-3 py-1 transition-colors w-full rounded hover:bg-white/10">
                  <HelpIcon classes="inline-block mr-3" />
                  <span>Contact Support</span>
                </span>
              </a>
              <a href={DOCS_LINK} className="menu-link" target="_blank">
                <span className="px-2 py-1 transition-colors w-full rounded hover:bg-white/10">
                  <MenuBookIcon classes="inline-block mr-2" />
                  <span>Documentation</span>
                </span>
              </a>
              <a
                href="https://github.com/RallyHereInteractive/unreal-plugins/releases/latest"
                target="_blank"
                className="menu-link"
              >
                <span className="px-2 py-1 transition-colors w-full rounded hover:bg-white/10">
                  <DownloadIcon classes="inline-block mr-2" />
                  <span>Download SDK</span>
                </span>
              </a>
              {permissionData?.isGlobalAdmin && (
                <Link to="/admin" className="menu-link">
                  <span
                    className={`px-2 py-1 transition-colors w-full rounded ${
                      activeMenuElem === "/admin"
                        ? "text-white hover:bg-selectedPurple bg-selectedPurple "
                        : "hover:bg-white/10"
                    }`}
                  >
                    <CorporateFareIcon classes={`inline-block mr-2`} />
                    <span>Admin</span>
                  </span>
                </Link>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
