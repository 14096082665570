import { useState, useContext, useEffect } from "react";
import { useSandboxStyles } from "../styles";
import AccountContext from "contexts/AccountContext";
import { useAuth } from "contexts/AuthContext";
import RHLoadingMask from "components/common/RHLoadingMask";
import Snackbar from "@mui/material/Snackbar";
import RHEditableTable from "components/common/RHEditableTable";
import * as helpers from "common/helpers";
import {
  pricePointApi,
  pricePointRowKey,
  pricePointColumns,
  pricePointEntry,
  breakPointColumns,
  breakPointEntry,
  breakPointRowKey,
} from "../definitions";
import { fetchDirectly } from "common/apiUtils";

export default function RHPricePointPage() {
  const classes = useSandboxStyles();

  const { currentStateData } = useContext(AccountContext);
  const { getAccessTokenSilently } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [selectedTableRow, setSelectedTableRow] = useState();
  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });

  const tableApi = pricePointApi;
  const tableRowKey = pricePointRowKey;
  const tableColumns = pricePointColumns;
  const tableVisibleColumns = ["name", "description", "last_modified_timestamp"];
  const tableInitialState = helpers.getInitialTableState(tableVisibleColumns, tableColumns);
  const tableFriendlyName = "Price Point";
  const tableEntry = pricePointEntry;
  const tableIsCatalog = true;
  const [pricepointData, setPricepointData] = useState();
  const [selectedBreakpoint, setSelectedBreakpoint] = useState();

  const childTableApi = pricePointApi;
  // const childTableInitialState = helpers.getInitialTableState(childTableVisibleColumns, childTableColumns);
  const childTableFriendlyName = "Breakpoints";

  const tabModel = [
    { tabKey: "current_breakpoints", tabLabel: "Current Breakpoints" },
    { tabKey: "pre_sale_breakpoints", tabLabel: "Pre-sale Breakpoints" },
  ];

  async function fetchTableData() {
    try {
      const resp = await fetchDirectly({
        fullUrl: `v1/sandbox/${currentStateData?.sandbox?.sandboxId}/price-point?expand=*&sort_by=price_point_id&sort_order=asc`,
        method: "GET",
        token: await getAccessTokenSilently(),
      });
      setPricepointData(resp.data);
      return resp.data;
    } catch (error) {
      console.error("Error message:", error.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function createPricepointHandler(apiName, onDataRefresh, sandboxId, editedItem, customArgs) {
    try {
      const resp = await fetchDirectly({
        fullUrl: `v1/sandbox/${sandboxId}/price-point`,
        method: "POST",
        token: await getAccessTokenSilently(),
        body: { data: [editedItem] },
      });
      refreshData();
      return resp[0];
    } catch (error) {
      console.error("Error message:", error.message);
    }
  }

  async function updatePricepointHandler(apiName, onDataRefresh, rowId, sandboxId, editedItem, customArgs) {
    try {
      const resp = await fetchDirectly({
        fullUrl: `v1/sandbox/${sandboxId}/price-point/${rowId}`,
        method: "PUT",
        token: await getAccessTokenSilently(),
        body: editedItem,
      });
      refreshData();

      await fetchTableData();
      setSelectedTableRow(resp);
      return resp;
    } catch (error) {
      console.error("Error message:", error.message);
    }
  }

  async function deletePricepointHandler(apiName, onDataRefresh, rowId, sandboxId, selectedItem, customArgs) {
    await fetchDirectly({
      fullUrl: `v1/sandbox/${sandboxId}/price-point/${rowId}`,
      method: "DELETE",
      token: await getAccessTokenSilently(),
    });
    refreshData();
  }

  async function handleBreakpointUpdate(apiName, onDataRefresh, rowId, sandboxId, editedItem, customArgs, selectedTab) {
    const prunedCurrencies = editedItem?.currencies.map((item) => {
      if (item?.price_break_point_currency_id === "") {
        delete item.price_break_point_currency_id;
      }
      return item;
    });

    const breakpoints = selectedTableRow[selectedTab]
      ? selectedTableRow[selectedTab].reduce((acc, item) => {
          if (item?.price_break_point_id === editedItem?.price_break_point_id) {
            acc.push({ ...editedItem, currencies: prunedCurrencies });
            return acc;
          }
          acc.push(item);
          return acc;
        }, [])
      : [];

    const mappedEditedItem = {
      ...selectedTableRow,
      [selectedTab]: breakpoints,
    };

    const item = await fetchDirectly({
      fullUrl: `v1/sandbox/${sandboxId}/price-point/${selectedTableRow.price_point_id}`,
      token: await getAccessTokenSilently(),
      method: "PUT",
      body: mappedEditedItem,
    });

    await fetchTableData();
    setSelectedTableRow(item);
    return item;
  }

  async function handleBreakpointCreate(apiName, onDataRefresh, sandboxId, editedItem, customArgs, selectedTab) {
    const prunedCurrencies = editedItem?.currencies.map((item) => {
      if (item?.price_break_point_currency_id === "") {
        delete item.price_break_point_currency_id;
      }
      return item;
    });

    let breakpoints;
    if (selectedTableRow?.[selectedTab].length === 0) {
      breakpoints = [{ ...editedItem, currencies: prunedCurrencies }];
    } else {
      breakpoints = [...selectedTableRow?.[selectedTab], editedItem];
    }

    const mappedEditedItem = {
      ...selectedTableRow,
      [selectedTab]: breakpoints,
    };

    const item = await fetchDirectly({
      fullUrl: `v1/sandbox/${sandboxId}/price-point/${selectedTableRow.price_point_id}`,
      token: await getAccessTokenSilently(),
      method: "PUT",
      body: mappedEditedItem,
    });

    await fetchTableData();
    setSelectedTableRow(item);
    return item;
  }

  async function handleBreakpointDelete(
    apiName,
    onDataRefresh,
    rowId,
    sandboxId,
    selectedItem,
    customArgs,
    selectedTab
  ) {
    const breakpoints = selectedTableRow[selectedTab].reduce((acc, item) => {
      if (item?.price_break_point_id !== rowId) {
        acc.push(item);
      }
      return acc;
    }, []);

    const mappedEditedItem = {
      ...selectedTableRow,
      [selectedTab]: breakpoints,
    };

    const item = await fetchDirectly({
      fullUrl: `v1/sandbox/${sandboxId}/price-point/${selectedTableRow.price_point_id}`,
      token: await getAccessTokenSilently(),
      method: "PUT",
      body: mappedEditedItem,
    });

    await fetchTableData();
    setSelectedTableRow(item);
    return item;
  }

  const refreshData = () => {
    return fetchTableData();
  };

  function customFilterFunc(field) {
    return !childTableHideInputArray.includes(field.field);
  }

  useEffect(() => {
    if (currentStateData?.sandbox?.sandboxId) {
      fetchTableData();
    }
  }, [currentStateData]);

  return isLoading ? (
    <RHLoadingMask />
  ) : (
    <>
      <RHEditableTable
        title={tableFriendlyName}
        friendlyName={tableFriendlyName}
        friendlyNameKey={"name"}
        apiName={tableApi}
        rowKey={tableRowKey}
        columns={tableColumns}
        tableData={pricepointData}
        initialState={tableInitialState}
        sandbox={currentStateData?.sandbox}
        classes={classes}
        catalog={tableIsCatalog}
        setSnackBarState={setSnackBarState}
        setSnackBar={setSnackBar}
        editableInline={false}
        onDataRefresh={refreshData}
        // textFieldFilter={helpers.customFilterFunc}
        retrieveSelectedRows={helpers.retrieveSelectedRows(setSelectedTableRow)}
        retrieveNewItemAsSelectedRow
        onSelectedRowsChanged={helpers.onSelectedRowsChanged(setSelectedTableRow)}
        newModel={tableEntry}
        newModelArgs={[currentStateData?.sandbox?.sandboxId]}
        createHandler={createPricepointHandler}
        updateHandler={updatePricepointHandler}
        deleteHandler={deletePricepointHandler}
      />
      {selectedTableRow && (
        <RHEditableTable
          title={childTableFriendlyName}
          friendlyName={childTableFriendlyName}
          apiName={childTableApi}
          rowKey={breakPointRowKey}
          columns={breakPointColumns}
          tableData={selectedTableRow}
          initialState={tableInitialState}
          sandbox={currentStateData?.sandbox}
          classes={classes}
          setSnackBarState={setSnackBarState}
          setSnackBar={setSnackBar}
          editableInline={false}
          // textFieldFilter={customFilterFunc}
          newModel={breakPointEntry}
          newModelArgs={[currentStateData?.sandbox?.sandboxId, selectedTableRow?.[tableRowKey]]}
          onDataRefresh={refreshData}
          createHandler={handleBreakpointCreate}
          updateHandler={handleBreakpointUpdate}
          deleteHandler={handleBreakpointDelete}
          tabModel={tabModel}
        />
      )}
      <Snackbar
        className="snackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarState}
        message={snackBar.message}
        severity={snackBar.severity}
        key={"bottom-center"}
        autoHideDuration={3000}
        onClose={() => setSnackBarState(false)}
      />
    </>
  );
}
