import { useState, useEffect } from "react";
import { fetchDirectly, csvToArray, arrayToCsv } from "../helpers";
import ArmadaTable from "../ArmadaTable";
import Snackbar from "@mui/material/Snackbar";
import { useAuth } from "contexts/AuthContext";
import { columns, NewEntry } from "./colDefs";
import { columns as childColumns, NewEntry as NewChildEntry } from "../ArmadaProductAssignments/colDefs";

export default function ArmadaHosts() {
  const ROW_KEY = "host_id";
  const ENDPOINT = "host";
  const CHILD_ROW_KEY = "simple_product_assignment_id";
  const CHILD_ENDPOINT = "simple_product_assignment";

  const { getAccessTokenSilently } = useAuth();

  const [selectedHostRow, setSelectedHostRow] = useState();
  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "",
    severity: "success",
  });

  const [data, setData] = useState();
  const [hostUpLoadData, setHostUploadData] = useState();
  const [childData, setChildData] = useState();

  // The host response has nested objects and allows for more mutations that we want to allow for on this UI. This function will mutate the host object to only include the fields we want to allow for editing.
  function mutateHost(host) {
    return Object.entries(host).reduce((acc, [key, value]) => {
      if (typeof value === "object" && value[`${key}_id`]) {
        return {
          ...acc,
          [`${key}_id`]: value[`${key}_id`],
          [`${key}_friendly`]: value.name ?? value.description ?? value.purchase_order,
        };
      }
      // And this exception
      if (key === "machine_class_overrides") {
        return {
          ...acc,
          machine_class_overrides_id: value.machine_class_id,
          machine_class_overrides_friendly: value.name ?? value.description,
        };
      }
      return { ...acc, [key]: value };
    }, {});
  }

  async function readData() {
    let response = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "GET",
      token: await getAccessTokenSilently(),
    });

    const mutatedResponse = response.map((host) => mutateHost(host));
    setData(mutatedResponse);
  }

  async function updateHandler(editedItem, noRead = false) {
    const item = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "PATCH",
      token: await getAccessTokenSilently(),
      body: Object.entries(editedItem).reduce((acc, [key, value]) => {
        if (key === ROW_KEY) return acc;
        return { ...acc, [key]: value };
      }, {}),
      dataId: editedItem[ROW_KEY],
    });
    if (!noRead) await readData();
    return item;
  }

  async function createHandler(editedItem, noRead = false) {
    const item = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "POST",
      token: await getAccessTokenSilently(),
      body: editedItem,
    });
    if (!noRead) await readData();
    return item;
  }

  async function deleteHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "DELETE",
      token: await getAccessTokenSilently(),
      dataId: editedItem.host_id,
    });
    await readData();
    return item;
  }

  async function onImport(event) {
    var reader = new FileReader();
    reader.readAsText(event.target.files[0]);
    reader.onloadend = function (evt) {
      if (evt.target.readyState === FileReader.DONE) {
        const hostsObj = csvToArray(evt.target.result);
        updateHosts(hostsObj);
      }
    };
  }

  async function onExport() {
    const csv = arrayToCsv(data);
    const element = document.createElement("a");
    const file = new Blob([csv], { type: "text/csv" });
    element.href = URL.createObjectURL(file);
    element.download = "hosts.csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
  }

  async function updateHosts(hostsObj) {
    let responses = [];
    for (let i = 0; i < hostsObj.length; i++) {
      const trimmedObj = Object.entries(hostsObj[i]).reduce((acc, [key, value]) => {
        if (value === "") return acc;
        return { ...acc, [key]: value };
      }, {});
      try {
        const item = !trimmedObj?.host_id
          ? await createHandler(trimmedObj, true)
          : await updateHandler(trimmedObj, true);
        responses.push({ public_hostname: item.public_hostname, status: "success" });
      } catch (err) {
        responses.push({
          public_hostname: trimmedObj.public_hostname,
          status: "error",
          detail: err?.response?.data?.desc,
        });
      }
    }

    await readData();
    alert(JSON.stringify(responses));
  }

  function createHostColVisibilityModel() {
    var keepers = [
      ROW_KEY,
      "internal_hostname",
      "virtual_machine",
      "public_hostname",
      "simple_product_assignments",
      "internal_ipv4",
      "public_ipv4",
      "geographic_location_id",
      "datacenter_location_id",
      "datacenter_provider_id",
      "datacenter_contract_id",
      "machine_class_id",
      "machine_image_id",

    ];
    var hiders = columns
      .map((x) => x.field)
      .filter((x) => !keepers.includes(x))
      .reduce((dict, el) => ((dict[el] = false), dict), {});
    return hiders;
  }

  function customFilterFunc(field) {
    return !["labels"].includes(field.field);
  }

  async function readSpaData() {
    let response = await fetchDirectly(
      {
        endpoint: CHILD_ENDPOINT,
        method: "GET",
        token: await getAccessTokenSilently(),
      },
      { host_ids: selectedHostRow?.host_id ?? "" }
    );
    setChildData(response);
  }

  async function updateSpaHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: CHILD_ENDPOINT,
      method: "PATCH",
      body: editedItem,
      dataId: editedItem[CHILD_ROW_KEY],
      token: await getAccessTokenSilently(),
    });
    await readSpaData();
    return item;
  }

  async function createSpaHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: CHILD_ENDPOINT,
      method: "POST",
      body: editedItem,
      token: await getAccessTokenSilently(),
    });
    await readSpaData();
    return item;
  }
  
    async function deleteSpaHandler(editedItem) {
    const item = await fetchDirectly({
      endpoint: ENDPOINT,
      method: "DELETE",
      token: await getAccessTokenSilently(),
      dataId: editedItem.simple_product_assignment_id,
    });
    await readSpaData();
    return item;
  }

  function createSpaColVisibilityModel() {
    var keepers = [ROW_KEY, "name", "count", "host_id"];
    var hiders = columns
      .map((x) => x.field)
      .filter((x) => !keepers.includes(x))
      .reduce((acc, curr) => ((acc[curr] = false), acc), {});
    return hiders;
  }

  function customSpaFilterFunc(field) {
    return ![].includes(field.field);
  }

  useEffect(() => {
    readData();
  }, []);

  useEffect(() => {
    if (selectedHostRow) {
      readSpaData();
    }
  }, [selectedHostRow]);

  return !data ? null : (
    <>
      <ArmadaTable
        title="Host"
        friendlyName="Host"
        friendlyNameKey="public_hostname"
        rowKey={ROW_KEY}
        columns={columns}
        tableData={data && data}
        textFieldFilter={customFilterFunc}
        setSnackBarState={setSnackBarState}
        retrieveSelectedRows={setSelectedHostRow}
        setSnackBar={setSnackBar}
        newModel={NewEntry}
        updateHandler={updateHandler}
        createHandler={createHandler}
        deleteHandler={deleteHandler}
        retrieveNewItemAsSelectedRow
        onImport={onImport}
        onExport={onExport}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
          columns: {
            columnVisibilityModel: createHostColVisibilityModel(),
          },
          sorting: {
            sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
          },
        }}
      />
      {selectedHostRow && (
        <ArmadaTable
          title="Simple Product Assignment"
          friendlyName="Simple Product Assignment"
          friendlyNameKey="name"
          rowKey={CHILD_ROW_KEY}
          columns={childColumns}
          tableData={childData && childData}
          textFieldFilter={customSpaFilterFunc}
          setSnackBarState={setSnackBarState}
          setSnackBar={setSnackBar}
          newModel={NewChildEntry}
          updateHandler={updateSpaHandler}
          createHandler={createSpaHandler}
          deleteHandler={deleteSpaHandler}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
            columns: {
              columnVisibilityModel: createSpaColVisibilityModel(),
            },
            sorting: {
              sortModel: [{ field: "last_modified_timestamp", sort: "desc" }],
            },
          }}
        />
      )}
      <Snackbar
        className="snackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarState}
        message={snackBar.message}
        severity={snackBar.severity}
        key={"bottom-center"}
        autoHideDuration={3000}
        onClose={() => setSnackBarState(false)}
      />
    </>
  );
}
