// FloatingActionButtons.js
import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

function FloatingActionButtons({ onUp, onDown, onClose }) {
  const floatingBoxStyle = {
    position: "absolute",
    top: "80px",
    left: -60,
    transform: "translateY(-50%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#1e1e1e",
    borderRadius: "2px",
    padding: "5px",
    zIndex: 1400,
    boxShadow: "-4px 0px 8px 0px rgba(0,0,0,0.75)",
  };

  const iconButtonStyle = {
    width: "24px",
    height: "24px",
    padding: "17.5px",
  };

  return (
    <Box sx={floatingBoxStyle}>
      <IconButton onClick={onClose} sx={iconButtonStyle}>
        <CloseIcon style={{ color: "white" }} />
      </IconButton>
      <IconButton onClick={onUp} sx={iconButtonStyle}>
        <ArrowUpwardIcon style={{ color: "white" }} />
      </IconButton>
      <IconButton onClick={onDown} sx={iconButtonStyle}>
        <ArrowDownwardIcon style={{ color: "white" }} />
      </IconButton>
    </Box>
  );
}

export default FloatingActionButtons;
