import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import BreadCrumbs from "./BreadCrumbs";
import AccountContext from "../../contexts/AccountContext";
import routeNames from "./routeNames";
import { capitalizeFirstLetters } from "../../common/helpers";
import rhLogo from "../../assets/rally-here-logo.png";
import RHMendableSearchBar from "../common/RHMendableSearchBar";
import UserMenu from "./UserMenu";
import { useQuery } from "react-query";
import { apiRequest, useApiToken } from "../../common/apiUtils";
import definitions from "../../common/definitions.json";

export default function AppBar({ menuOpen }) {
  const { currentStateData, permissionData } = useContext(AccountContext);
  const [opsFetchConfig, setOpsFetchConfig] = useState();
  const [opsUniqueName, setOpsUniqueName] = useState("");
  const token = useApiToken();

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
  };

  function getPageTitle() {
    const pathSegments = location.pathname.split("/");
    pathSegments.shift();
    if (pathSegments.length === 1 && pathSegments[0] === "admin") {
      return "Admin Dashboard";
    }
    if (["sandbox", "environment", "org", "product"].includes(pathSegments[pathSegments.length - 2])) {
      switch (pathSegments[pathSegments.length - 2]) {
        case "sandbox":
        case "environment":
        case "product":
          return `${capitalizeFirstLetters(pathSegments[pathSegments.length - 2], "-")} Dashboard`;
        case "org":
          return "Organization Dashboard";
      }
    }
    return false;
  }

  const { data, isLoading, isError, error, refetch, isFetching } = useQuery(
    [opsFetchConfig],
    () => apiRequest(token, opsFetchConfig?.endpoint, opsFetchConfig?.replacements, null, {}, {}),
    {
      enabled: !!permissionData?.isGlobalAdmin && !!opsFetchConfig,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onSuccess: (fetchedData) => {
        // Success handling
        setOpsUniqueName(fetchedData.unique_name);
      },
    }
  );

  useEffect(() => {
    if (!currentStateData?.product) return;
    let opsEndpointKey = "";
    if (currentStateData?.product && currentStateData?.environment === undefined) {
      opsEndpointKey = "ProductOps";
    } else if (currentStateData?.environment) {
      opsEndpointKey = "EnvironmentOpsResponse";
    }

    if (opsEndpointKey) {
      const endpoint = definitions?.schemas[opsEndpointKey]?.endpoints?.find((e) => e.method === "get");

      setOpsFetchConfig({
        replacements: {
          org_identifier: currentStateData?.org?.orgId,
          product_identifier: currentStateData?.product?.productId,
          environment_id: currentStateData?.environment?.environmentId,
        },
        endpoint,
      });
    }
  }, [currentStateData]);

  return !currentStateData ? null : (
    <div className="backdrop-blur flex justify-between w-full text-fontBase px-8 py-2 sm:py-4 relative sm:sticky top-0 bg-black/80 z-20">
      <Link to="/">
        <img className="absolute inline-block left-0 right-0 mx-auto my-4 sm:hidden w-[130px]" src={rhLogo} />
      </Link>
      <div className="flex flex-col flex-1 justify-between">
        <span className={`hidden sm:block pl-5 ${!menuOpen ? "lg:pl-5" : "lg:pl-0"}`}>
          <BreadCrumbs currentStateData={currentStateData} />
        </span>

        <div className="title text-white text-2xl hidden sm:flex flex-col flex-1 justify-end">
          {routeNames?.[location.pathname.split("/").pop()] ?? getPageTitle()}
        </div>
      </div>
      <div className="right flex flex-col justify-between items-end">
        <div className="flex items-center mb-4">
          <RHMendableSearchBar />
          <div className="user ml-0">
            <UserMenu />
          </div>
        </div>
        <div className="hidden lg:block">
          {currentStateData?.org && !currentStateData?.product && (
            <p
              onClick={() => copyToClipboard(currentStateData?.org?.orgId)}
              className="text-fontBase/60 tracking-wider text-xs cursor-pointer uppercase mb-1"
              title="Copy to clipboard"
            >
              Org ID: {currentStateData?.org?.orgId}
            </p>
          )}
          {currentStateData?.product && !currentStateData?.sandbox && !currentStateData?.environment && (
            <p
              onClick={() => copyToClipboard(currentStateData?.product?.productId)}
              className="text-fontBase/60 tracking-wider text-xs cursor-pointer uppercase mb-1"
              title="Copy to clipboard"
            >
              Product ID: {currentStateData?.product?.productId}
            </p>
          )}
          {currentStateData?.product &&
            !currentStateData?.sandbox &&
            !currentStateData?.environment &&
            permissionData?.isGlobalAdmin && (
              <p
                onClick={() => copyToClipboard(opsUniqueName)}
                className="text-fontBase/60 tracking-wider text-xs cursor-pointer uppercase mb-1"
                title="Copy to clipboard"
              >
                Product Unique Name: {opsUniqueName}
              </p>
            )}
          {currentStateData?.sandbox && (
            <>
              <p
                onClick={() => copyToClipboard(currentStateData?.sandbox?.sandboxId)}
                className="text-fontBase/60 tracking-wider text-xs cursor-pointer uppercase"
                title="Copy to clipboard"
              >
                Sandbox ID: {currentStateData?.sandbox?.sandboxId}
              </p>
            </>
          )}
          {currentStateData?.environment && (
            <>
              <p
                onClick={() => copyToClipboard(currentStateData.environment.environmentId)}
                className="text-fontBase/60 tracking-wider text-xs cursor-pointer uppercase"
                title="Copy to clipboard"
              >
                Environment ID: {currentStateData.environment.environmentId}
              </p>
              <p
                onClick={() => copyToClipboard(currentStateData.environment.baseUrl)}
                className="text-fontBase/60 tracking-wider text-xs cursor-pointer uppercase"
                title="Copy to clipboard"
              >
                Environment URL: {currentStateData.environment.baseUrl}
              </p>
              <p
                onClick={() => copyToClipboard(opsUniqueName)}
                className="text-fontBase/60 tracking-wider text-xs cursor-pointer uppercase"
                title="Copy to clipboard"
              >
                Environment Unique Name: {opsUniqueName}
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
