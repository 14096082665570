import React, { useEffect, useState, useCallback } from "react";
import { useDataContext } from "../../../contexts/DataContext";
import { IconButton, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ResizableDrawer from "./ResizableDrawer";
import FloatingActionButtons from "./FloatingActionButtons";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";

function RHDrawer() {
  const { dataContext, updateDataContext } = useDataContext();
  const [isOpen, setIsOpen] = useState(false);

  const headerStyle = {
    color: "#FFFFFF95",
    marginLeft: "40px",
    paddingTop: "15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 1301,
  };
  // Determine the top offset of the drawer content

  const pullTabStyle = {
    width: "30px",
    height: "100px",
    position: "fixed",
    right: "0px",
    top: "20%",
    backgroundColor: "#1E1E1E",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    cursor: "pointer",
    zIndex: 1300,
    boxShadow: "-2px 0px 5px rgba(0, 0, 0, 0.5)",
    transition: "transform 0.3s",
    transform: isOpen ? "rotate(180deg)" : "none",
  };

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
    updateDataContext({ shouldOpenDrawer: !isOpen });
  }, [isOpen, updateDataContext]);

  const handleUp = useCallback(() => {}, []);

  const handleDown = useCallback(() => {}, []);

  const handleClose = useCallback(() => {
    handleToggle();
  }, [handleToggle]);

  useEffect(() => {
    setIsOpen(dataContext?.shouldOpenDrawer ?? false);
  }, [dataContext?.shouldOpenDrawer]);

  return (
    <>
      {/* <Box sx={pullTabStyle} onClick={handleToggle}>
        <ArrowBackIosNew style={{ color: "white" }} />
      </Box> */}
      <ResizableDrawer isOpen={isOpen} onClose={handleToggle}>
        <Box
          sx={{
            position: "fixed",
            top: "20px",
            width: "256px",
            zIndex: 1301,
            ...headerStyle,
          }}
        ></Box>

        <Box
          sx={{
            height: `calc(100%)`,
            overflowY: "auto",
            marginLeft: "25px",
            paddingTop: "20px",
          }}
        >
          <>
            <>
              <div>
                <div>Placeholder</div>
              </div>
            </>
          </>
        </Box>

        <FloatingActionButtons onUp={handleUp} onDown={handleDown} onClose={handleClose} />
      </ResizableDrawer>
    </>
  );
}

export default RHDrawer;
