import { useState, useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import AccountContext from "../../contexts/AccountContext";
import { ChevronIcon } from "../../assets/svgs";

export default function MenuDropdown({ elem, showSelected, toggledOn }) {
  const [active, setActive] = useState(false);
  const [dropdownHeight, setDropdownHeight] = useState("0px");
  const { currentStateData } = useContext(AccountContext);

  const location = useLocation();

  function toggleDropdown() {
    if (!active) {
      const el = document.getElementById((elem.name + "dropdown").replace(/\s/g, ""));
      setDropdownHeight(el.scrollHeight + "px");
    } else if (showSelected) {
      setDropdownHeight("36px");
    } else setDropdownHeight("0px");

    setActive((prev) => !prev);
  }

  function moveSelectedFirst(a, b) {
    if (!showSelected) return 0;
    else if (location.pathname.split("/").includes(a.route)) return -1;
    else return 1;
  }

  useEffect(() => {
    const activeRoute = elem.nestedItems.find((x) => {
      return (
        location.pathname.split("/").includes(x.route) ||
        // this first check is for hacky routes like game config to split up '/game-config/epic' from the rest of the pathname
        location.pathname.split(currentStateData?.sandbox?.shortName)[1] === `/${x.route}`
      );
    });

    if (activeRoute) {
      toggleDropdown();
    }
  }, []);

  return (
    <div className="relative">
      <div className="menu-link cursor-pointer" onClick={toggleDropdown}>
        <div key={elem.name + "parent"} className={`px-2 py-1 w-full text-sm flex items-center`}>
          {elem.icon}
          <span>{elem.name}</span>
          <ChevronIcon classes={`inline ml-auto transition-[transform] ${active ? "rotate-180" : "rotate-0"}`} />
        </div>
      </div>
      <ul
        id={(elem.name + "dropdown").replace(/\s/g, "")}
        className={`w-full overflow-hidden transition-[height]`}
        style={{ height: dropdownHeight }}
      >
        {elem.nestedItems.sort(moveSelectedFirst).map((config) => (
          <li
            key={config.route + "sandbox"}
            className={`menu-link ml-8 ${toggledOn(config.route) ? "" : "pointer-events-none opacity-50"}`}
          >
            <Link
              to={`/org/${currentStateData?.org?.shortName}/product/${currentStateData?.product?.shortName}/sandbox/${currentStateData?.sandbox?.shortName}/${config.route}`}
              className={`block px-2 py-1 transition-colors w-full rounded ${
                //this first check is for hacky routes like game config to split up '/game-config/epic' from the rest of the pathname
                location.pathname.split(currentStateData?.sandbox?.shortName)[1] === `/${config.route}` ??
                location.pathname.split("/").includes(config.route)
                  ? "text-white bg-selectedPurple hover:bg-selectedPurple"
                  : "hover:bg-white/10"
              }`}
            >
              <span>
                <span className="text-sm">{config.name}</span>
              </span>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
