import { Link } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";

export default function UserMenu() {
  const { user, logout } = useAuth();

  return (
    <div className="group cursor-pointer relative">
      <img src={user?.picture} alt={user?.name} className="rounded-full w-10" />
      <div
        className="overflow-hidden group-hover:max-h-36 max-h-0 absolute origin-bottom transition-all bg-neutral-700 rounded py-0 px-1 group-hover:py-2 right-0 opacity-0 group-hover:opacity-100 z-10"
        style={{ top: "calc(100% + 10px)" }}
      >
        <Link to="/tools" className="hover:bg-white/10 rounded-sm px-2 py-1 block">
          Tools
        </Link>
        <Link to="/profile" className="hover:bg-white/10 rounded-sm px-2 py-1 block">
          Profile
        </Link>
        <div onClick={logout} className="hover:bg-white/10 rounded-sm px-2 py-1 block">
          Logout
        </div>
      </div>
    </div>
  );
}
