export const columns = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type",
    minWidth: 200,
    required: true,
    editable: true,
    flex: 1,
  },
  {
    field: "machine_class_id",
    headerName: "Machine Class ID",
    minWidth: 200,
    type: "metadata",
    flex: 1,
  },
  // {
  //   field: "labels",
  //   headerName: "Matchmaking Template Group",
  //   minWidth: 200,
  //   flex: 1,
  //   type: "enhancedautocomplete",
  //   editable: true,
  //   isDynamic: true,
  //   fullUrl: "armada",
  //   filterOnClient: true,
  //   idKey: "match_making_template_group_id",
  //   displayValue: "name",
  //   responseDataName: "groups",
  //   defaultOptions: [],
  // },
  {
    field: "datacenter_provider_id",
    headerName: "Datacenter Provider",
    minWidth: 200,
    flex: 1,
    type: "enhancedautocomplete",
    editable: true,
    isDynamic: true,
    fullUrl: "v1/armada/datacenter_provider",
    filterOnClient: true,
    idKey: "datacenter_provider_id",
    displayValue: "name",
    defaultOptions: [],
  },
  {
    field: "monthly_price",
    headerName: "Monthly Price",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "hourly_price",
    headerName: "Hourly Price",
    minWidth: 200,
    min: 0,
    flex: 1,
  },
  {
    field: "cpu_count",
    headerName: "CPU Count",
    minWidth: 200,
    min: 0,
    type: "number",
    headerAlign: "left",
    flex: 1,
  },
  {
    field: "cpu_info",
    headerName: "CPU Info",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "cpu_type",
    headerName: "CPU Type",
    minWidth: 100,
    maxWidth: 100,
    flex: 1,
  },
  {
    field: "cpu_sockets",
    headerName: "CPU Sockets",
    minWidth: 200,
    min: 0,
    type: "number",
    headerAlign: "left",
    flex: 1,
  },
  {
    field: "cpu_cores",
    headerName: "CPU Cores",
    minWidth: 200,
    editable: true,
    type: "number",
    headerAlign: "left",
    flex: 1,
  },
  {
    field: "cpu_threads",
    headerName: "CPU Threads",
    minWidth: 200,
    min: 0,
    type: "number",
    headerAlign: "left",
    flex: 1,
  },
  {
    field: "memory_size_gigabytes",
    headerName: "Memory Size (GB)",
    minWidth: 200,
    min: 0,
    flex: 1,
    type: "number",
    headerAlign: "left",
  },
  {
    field: "memory_type",
    headerName: "Memory Type",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "memory_speed_rating",
    headerName: "Memory Speed Rating",
    minWidth: 200,
    min: 0,
    type: "number",
    headerAlign: "left",
    flex: 1,
  },
  {
    field: "memory_timings",
    headerName: "Memory Timings",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "disk_type",
    headerName: "Disk Type",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "disk_size_gigabytes",
    headerName: "Disk Size (GB)",
    minWidth: 200,
    min: 0,
    flex: 1,
    type: "number",
    headerAlign: "left",
  },
  {
    field: "disk_sequnetial_read",
    headerName: "Disk Sequential Read",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "disk_sequential_write",
    headerName: "Disk Sequential Write",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "disk_random_read",
    headerName: "Disk Random Read",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "disk_random_write",
    headerName: "Disk Random Write",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "disk_iops",
    headerName: "Disk IOPS",
    minWidth: 200,
    min: 0,
    flex: 1,
    type: "number",
    headerAlign: "left",
  },
  {
    field: "disk_latency_ms",
    headerName: "Disk Latency (ms)",
    minWidth: 200,
    min: 0,
    flex: 1,
    type: "number",
    headerAlign: "left",
  },
  {
    field: "network_gbps",
    headerName: "Network (Gbps)",
    minWidth: 200,
    min: 0,
    flex: 1,
    type: "number",
    headerAlign: "left",
  },
  {
    field: "gpu_type",
    headerName: "GPU Type",
    minWidth: 200,
    flex: 1,
  },
  {
    field: "gpu_count",
    headerName: "GPU Count",
    minWidth: 200,
    min: 0,
    flex: 1,
    type: "number",
    headerAlign: "left",
  },
  {
    field: "gpu_memory_gigabytes",
    headerName: "GPU Memory (GB)",
    minWidth: 200,
    min: 0,
    flex: 1,
    type: "number",
    headerAlign: "left",
  },
  { field: "datacenter_provider", nullify: true, alwaysHidden: true },
];

export class NewEntry {}
