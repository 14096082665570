import { useContext, useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import AccountContext from "../../contexts/AccountContext";
import { generateColor } from "../../common/colorUtils";
import RHLoadingMask from "../../components/common/RHLoadingMask";
import Switch from "@mui/material/Switch";
import EditDialog from "../environment/EditDialog";
import { StorageIcon, SettingsIcon, CollectionsIcon } from "../../assets/svgs";
import Snackbar from "@mui/material/Snackbar";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import EditIcon from "@mui/icons-material/Edit";

export default function RHProductPage() {
  const { currentStateData, permissionData, sandboxCopyIsEnabled } = useContext(AccountContext);
  const [showArchivedSandboxes, setShowArchivedSandboxes] = useState(false);
  const [showArchivedEnvironments, setShowArchivedEnvironments] = useState(false);
  const [environments, setEnvironments] = useState(false);
  const [sandboxes, setSandboxes] = useState(false);
  const [environmentEditOpen, setEnvironmentEditOpen] = useState({ open: false });
  const [sandboxEditOpen, setSandboxEditOpen] = useState({ open: false });
  const [sandboxCopyOpen, setSandboxCopyOpen] = useState({ open: false });
  const [isLoading, setIsLoading] = useState(true);
  const [snackBarState, setSnackBarState] = useState(false);
  const [snackBar, setSnackBar] = useState({
    message: "Successfully saved!",
    severity: "success",
  });

  const sortByName = (items, nameProp) => {
    if (!items) {
      return items;
    }
    return items.sort(function (a, b) {
      if (a[nameProp] < b[nameProp]) {
        return -1;
      }
      if (a[nameProp] > b[nameProp]) {
        return 1;
      }
      return 0;
    });
  };

  const handleSandboxArchiveChange = (event) => {
    setShowArchivedSandboxes(event.target.checked);
  };

  const handleEnvironmentArchiveChange = (event) => {
    setShowArchivedEnvironments(event.target.checked);
  };

  const showAddEnvironmentDialog = () => {
    setEnvironmentEditOpen({ open: true });
  };

  function handleEnvironmentDialogClose(value) {
    setEnvironmentEditOpen(false);
  }

  const showAddSandboxDialog = () => {
    setSandboxEditOpen({ open: true });
  };

  function handleSandboxDialogClose(value) {
    setSandboxEditOpen(false);
  }

  function handleSandboxCopyDialogClose(value) {
    setSandboxCopyOpen(false);
  }

  function handleEnvironmentEditClick(evt, environment) {
    setEnvironmentEditOpen({ open: true, environment: environment });
    return;
  }

  function handleSandboxEditClick(evt, sandbox) {
    setSandboxEditOpen({ open: true, sandbox: sandbox });
    evt.preventDefault();
    return;
  }

  function handleSandboxCopyClick(evt, sandbox) {
    setSandboxCopyOpen({ open: true, sandbox: sandbox });
    evt.preventDefault();
    return;
  }

  async function handleOnSave(msg) {
    setSnackBar({
      message: msg || "Successfully saved!",
      severity: "success",
    });
    setSnackBarState(true);
  }

  useEffect(() => {
    if (!currentStateData?.product) return;
    setEnvironments(sortByName(currentStateData?.product?.environments, "environmentName"));
    setSandboxes(sortByName(currentStateData?.product?.sandboxes, "sandboxName"));
    setIsLoading(false);
  }, [currentStateData]);

  return isLoading ? (
    <RHLoadingMask />
  ) : (
    <>
      {environments && (
        <>
          <Box component="span" m={0} display="flex" justifyContent="space-between" alignItems="center">
            <Box variant="contained">
              <Typography variant="h5" color="textPrimary">
                Environments
              </Typography>
            </Box>
            {permissionData?.isGlobalAdmin && (
              <Box variant="contained" className={`flex items-center`}>
                <Button onClick={showAddEnvironmentDialog}>+ Add New Environment</Button>
              </Box>
            )}
          </Box>

          <Box
            key="env_switch"
            component="span"
            m={0}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box variant="contained" className={`flex items-center`}>
              <Switch
                checked={showArchivedEnvironments}
                onChange={handleEnvironmentArchiveChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography variant="body1" color="text.secondary">
                Show Archived Environments
              </Typography>
            </Box>
          </Box>

          <Box key="env_list" className={`flex flex-wrap gap-5 w-full`}>
            {environments &&
              environments.map((environment, i) =>
                environment.archive && !showArchivedEnvironments ? (
                  <Fragment key={"archivedproduct" + i} />
                ) : (
                  <div key={environment.environmentId} className="relative">
                    <Link
                      className={`flex h-[165px] justify-center items-center w-menu cursor-pointer mr-2 mt-2  border-[1px] border-fontBase rounded`}
                      style={{ background: generateColor(environment.environmentId) }}
                      to={`/org/${currentStateData?.org.shortName}/product/${currentStateData?.product.shortName}/environment/${environment.shortName}`}
                    >
                      <div className="flex flex-col justify-center items-center">
                        <CollectionsIcon width="64" />
                        <h5 className="text-fontBase text-2xl">{environment.environmentName}</h5>
                      </div>
                    </Link>

                    {!sandboxCopyIsEnabled() && (
                      <div
                        className="absolute inline-block bottom-3 right-5 z-50 cursor-pointer"
                        onClick={(evt) => handleEnvironmentEditClick(evt, environment)}
                      >
                        <SettingsIcon height="25" width="25" />
                      </div>
                    )}

                    {sandboxCopyIsEnabled() && (
                      <div className="absolute inline-block bottom-0 right-1 z-50 cursor-pointer">
                        <Box sx={{}}>
                          <SpeedDial
                            ariaLabel="SpeedDial basic example"
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              right: 0,
                              "& .MuiFab-primary": {
                                width: 40,
                                height: 40,
                                backgroundColor: "transparent",
                                color: "#121212",
                                boxShadow: "none",
                                webkitBoxShadow: "none",
                                mozBoxShadow: "none",
                                "&:hover": { backgroundColor: "#1e1e1e" },
                              },
                            }}
                            icon={<SettingsIcon height="25" width="25" />}
                          >
                            <SpeedDialAction
                              key="Edit"
                              icon={<EditIcon />}
                              sx={{ marginBottom: "-5px" }}
                              tooltipTitle="Edit"
                              onClick={(evt) => handleEnvironmentEditClick(evt, environment)}
                            />
                          </SpeedDial>
                        </Box>
                      </div>
                    )}
                  </div>
                )
              )}

            <EditDialog
              type="environment"
              openRequest={environmentEditOpen}
              onClose={handleEnvironmentDialogClose}
              onSave={handleOnSave}
            />
          </Box>
        </>
      )}

      {sandboxes && (
        <>
          <Box component="span" mt={5} display="flex" justifyContent="space-between" alignItems="center">
            <Box variant="contained">
              <Typography variant="h5" color="textPrimary">
                Sandboxes
              </Typography>
            </Box>
            <Box variant="contained" className={`flex items-center`}>
              <Button onClick={showAddSandboxDialog}>+ Add New Sandbox</Button>
            </Box>
          </Box>

          <Box key="sandbox_switch" component="span" display="flex" justifyContent="space-between" alignItems="center">
            <Box variant="contained" className={`flex items-center`}>
              <Switch
                checked={showArchivedSandboxes}
                onChange={handleSandboxArchiveChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography variant="body1" color="text.secondary">
                Show Archived Sandboxes
              </Typography>
            </Box>
          </Box>

          <Box key="sandbox_list" className={`flex flex-wrap gap-5 w-full`}>
            {sandboxes &&
              sandboxes.map((sandbox, i) =>
                sandbox.archive && !showArchivedSandboxes ? (
                  <Fragment key={"archivedsandbox" + i} />
                ) : (
                  <div key={sandbox.sandboxId} className="relative">
                    <Link
                      key={sandbox.sandboxId}
                      className={`flex flex-col h-[165px] justify-center w-menu cursor-pointer mr-2 mt-2 items-center border-[1px] border-fontBase rounded`}
                      style={{ background: generateColor(`${sandbox.sandboxId}_s`) }}
                      to={`/org/${currentStateData?.org.shortName}/product/${currentStateData?.product.shortName}/sandbox/${sandbox.shortName}`}
                    >
                      <div className="flex flex-col justify-center items-center">
                        <StorageIcon width="64" />
                        <h5 className="text-fontBase text-2xl">{sandbox.sandboxName}</h5>
                      </div>
                    </Link>

                    {!sandboxCopyIsEnabled() && (
                      <div
                        className="absolute inline-block bottom-3 right-5 z-50 cursor-pointer"
                        onClick={(evt) => handleSandboxEditClick(evt, sandbox)}
                      >
                        <SettingsIcon />
                      </div>
                    )}

                    {sandboxCopyIsEnabled() && (
                      <div className="absolute inline-block bottom-0 right-1 z-50 cursor-pointer">
                        <Box sx={{}}>
                          <SpeedDial
                            ariaLabel="SpeedDial basic example"
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              right: 0,
                              "& .MuiFab-primary": {
                                width: 40,
                                height: 40,
                                backgroundColor: "transparent",
                                color: "#121212",
                                boxShadow: "none",
                                webkitBoxShadow: "none",
                                mozBoxShadow: "none",
                                "&:hover": { backgroundColor: "#1e1e1e" },
                              },
                            }}
                            icon={<SettingsIcon height="25" width="25" />}
                          >
                            <SpeedDialAction
                              key="Edit"
                              icon={<EditIcon />}
                              sx={{ marginBottom: "-5px" }}
                              tooltipTitle="Edit"
                              onClick={(evt) => handleSandboxEditClick(evt, sandbox)}
                            />
                            <SpeedDialAction
                              key="Copy"
                              icon={<FileCopyIcon />}
                              tooltipTitle="Copy To"
                              // tooltipOpen
                              onClick={(evt) => handleSandboxCopyClick(evt, sandbox)}
                            />
                          </SpeedDial>
                        </Box>
                      </div>
                    )}
                  </div>
                )
              )}

            <EditDialog
              type="sandbox"
              openRequest={sandboxEditOpen}
              onClose={handleSandboxDialogClose}
              onSave={handleOnSave}
            />
            <EditDialog type="sandbox-copy" openRequest={sandboxCopyOpen} onClose={handleSandboxCopyDialogClose} />
          </Box>
        </>
      )}

      <Snackbar
        className="snackBar"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackBarState}
        message={snackBar.message}
        severity={snackBar.severity}
        key={"bottom-center"}
        autoHideDuration={3000}
        onClose={() => setSnackBarState(false)}
      />
    </>
  );
}
